import React, { useState } from "react"
import { authHead, authMain } from './css/AuthPages.module.scss'
import { createFeedback } from '../http/feedbackApi'
import { useParams, useHistory } from "react-router-dom"
import { formCreate } from "./css/CreateTask.module.scss"
import { button } from "./css/LoginRegister.module.scss"
import { photosFigure } from "./css/HomeUp.module.scss"
import { CABINET_ROUTE } from "../utils/routeNames"
import Compressor from "compressorjs"

const Feedback = () => {
	const [type, setType] = useState("")
	const [description, setDescription] = useState("")
	const [photos, setPhoto] = useState([])
	const {id} = useParams()
	const {push} = useHistory()

	const createFeedbackHandler = async () => {
		try {
			const feedbackForm = new FormData()

			if (type.length<10 || description.length<20) {
				return alert('Опишите вашу проблему!')
			}

			feedbackForm.append("type", type)
			feedbackForm.append("description", description)

			const promises = []

			for (const photo of photos) {
				promises.push(new Promise(function (resolve, reject) {
					new Compressor(photo, {
						quality: 0.6,
						success(result) {
							feedbackForm.append('photo', result, photo.lastModified+'.'+photo.name.split('.').pop())
							resolve()
						},
						error(err) {
							console.log(err.message)
							reject()
						},
					})
				}))
			}

			Promise.all(promises).then(async function () {
				await createFeedback(feedbackForm).then(res => {
					alert(res)
					push(CABINET_ROUTE)
				})
			})
			for (const p of photos) {
				feedbackForm.append('photo', p)
			}
		} catch (e) {
			alert(e.response.data.message)
		}
	}

	return (
		<>
			<header className={authHead}>
				<h2>Решение</h2>
			</header>
			<main className={authMain}>
				<form className={formCreate} onSubmit={e=>e.preventDefault()}>
					<label>
						<span>
							Тип проблемы:
						</span>
						<input
							type="text"
							placeholder="В чем возникает проблема (минимум 10 букв)"
							value={type}
							onChange={e => setType(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Описание:
						</span>
						<input
							type="text"
							placeholder="Опишите вашу проблему (минимум 20 букв)"
							value={description}
							onChange={e => setDescription(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Скриншот:
						</span>
						<input
							type="file"
							accept="image/*"
							multiple
							onChange={e=>setPhoto(e.target.files)}
						/>
					
					</label>
					<figure className={photosFigure}>
						{[...photos]
							.map(photos =>
								<img
									key={photos.name}
									src={URL.createObjectURL(photos)}
									alt={`Фото задачи №${id}`}
								/>
							)
						}
					</figure>
					<a href="tel:+998995001040">
						Оставить отзыв по телефону
					</a>
					<a href="https://t.me/+998330234507">
						Оставить отзыв по телеграм
					</a>
					<button
						type="submit"
						className={button}
						style={{marginTop:"auto"}}
						onClick={createFeedbackHandler}
						>
						Создать
					</button>
				</form>
			</main>
		</>
	)
}

export default Feedback
