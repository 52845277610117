export const LOGIN_ROUTE 				=	'/login'
export const REGISTER_ROUTE 			=	'/register'
export const HISTORY_ROUTE 				=	'/history'
export const CABINET_ROUTE 				=	'/cabinet'
export const HOME_ROUTE 				=	'/'
export const TASKS_ROUTE 				=	'/tasks'
export const FEEDBACK_ROUTE				= 	'/feedback'
export const CHANGE_PASS_ROUTE 			=	'/update/password'
export const CHANGE_USER_PASS_ROUTE 	=	'/update/user/password'
export const CHANGE_NUMBER_ROUTE 		=	'/update/number'
export const CHANGE_ROLE_ROUTE 			=	'/update/role'
export const CHANGE_FEEDBACK_ROUTE		= 	'/update/feedback'
export const PAGE_404_ROUTE 			=	'/404'
export const CREATE_TASK_ROUTE			= 	'/create'
export const CREATE_GRAPH_TASK_ROUTE	= 	'/create/graph'
export const CREATE_UNGRAPH_TASK_ROUTE	= 	'/create/ungraph'
export const CREATE_FEEDBACK_ROUTE		= 	'/create/feedback'
export const CREATE_ARTICLE_ROUTE		= 	'/create/article'
export const CREATE_SHCHU_ROUTE			= 	'/create/shchu'
export const CREATE_DEPARTMENT_ROUTE	= 	'/create/department'
export const CREATE_STATION_ROUTE		= 	'/create/station'
export const CREATE_VOLT_ROUTE			= 	'/create/volt'
export const REASON_ROUTE				= 	'/reason'
export const REASON_VIEW_ROUTE			= 	'/reason/view'
export const NOT_ASSIGNED_TASK_ROUTE	= 	'/assign'
export const MINE_TASKS_SHNS			= 	'/my/tasks'