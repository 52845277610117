import React, { useState, useContext } from "react"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { Context } from "../.."
import { useHistory, useParams } from "react-router-dom"
import { formCreate } from "../css/CreateTask.module.scss"
import SearchWorkersInput from "../../components/SearchWorkersSelect"
import { button } from "../css/LoginRegister.module.scss"
import moment from "moment"
import { generateCreatedTask } from "../../http/taskMiddleApi"
import { NOT_ASSIGNED_TASK_ROUTE } from "../../utils/routeNames"

const AssignTask = () => {
	const {tasksMidl, art, user, sstation} = useContext(Context)
	const {id} = useParams()
	const task = tasksMidl.notAssignedTasks.find(task => task.id === parseInt(id))
	const {description, repeatDay, StationId} = task
	const {date} = task.Graphs[0]
	const {article} = art.articles.find(a => task.ArticleId === a.id)
	const [workers, setWorkers] = useState([])
	const [selected, setSelected] = useState([])
	const [radioTag, setRadioTag] = useState([])
	const [sStationId, setSStationId] = useState("")
	const [selectValue, setSelectValue] = useState("0")
	const {push} = useHistory()

	const filtUser = (worker) => radioTag.find(user => user.id === worker).username.split(" ")

	const AssignTaskHandler = async () => {
		try {
			const data = await generateCreatedTask(id, Array.from(workers), sStationId)
			if(workers.size === 0) {return alert('Не выбрано работников')}
			if(workers.includes(user.user.id)) {
				tasksMidl.setMineTasks(
					task,
					Reflect.set(task.Graphs[0], 'workers', workers),
					Reflect.set(task.Graphs[0], 'GraphWorkers', workers.map(worker=> {
						return {
							UserId: worker, 
							User: {
								firstname: filtUser(worker)[0],
								middlename: filtUser(worker)[1]
							}
						}
					})),
					task.Article = {article}
				)
			} else {
				tasksMidl.setActiveTasks(
					task,
					Reflect.set(task.Graphs[0], 'workers', workers),
					Reflect.set(task.Graphs[0], 'GraphWorkers', workers.map(worker=> {
						return {
							UserId: worker, 
							User: {
								firstname: filtUser(worker)[0],
								middlename: filtUser(worker)[1]
							}
						}
					}))
				)
			}
			tasksMidl.removeAssignedTask(tasksMidl._notAssignedTasks.filter(task => task.id !== parseInt(id)))
			setRadioTag([])
			setSelectValue("0")
			alert(data)
			push(NOT_ASSIGNED_TASK_ROUTE)
		} catch (e) {
			console.log(e.response.data.message)
		}
	}

	return (
		task ?
		<>
			<header className={authHead}>
				<h2>Задача № {task.id}</h2>
			</header>
			<main className={authMain}>
				<form className={formCreate} onSubmit={e=>e.preventDefault()}>
					<label>
						<span>
							Задача:
						</span>
						<input
							value={article}
							// onChange={e => setArticle(e.target.value)}
							disabled
						/>
					</label>
					<label>
						<span>
							Описание задачи:
						</span>
						<input
							placeholder="Описание"
							value={description}
							// onChange={e=>setDescription(e.target.value)}
							disabled
						/>
					</label>
					<label>
						<span>
							Дата:
						</span>
						<input
							value={moment(date).format('YYYY-MM-DD')}
							disabled
						/>
					</label>
					<label>
						<span>
							Повторять каждый:
						</span>
						<input
							value={repeatDay}
							// onChange={e=>setRepeatDay(e.target.value)}
							placeholder="Дни повторения"
							disabled
							type="number"
						/>
						<select value={1}
							disabled
						>
							<option value={1}>дней</option>
						</select>
					</label>
					<SearchWorkersInput
						setWorkers={setWorkers}
						selected={selected}
						setSelected={setSelected}
						radioTag={radioTag}
						setRadioTag={setRadioTag}
						selectValue={selectValue}
						setSelectValue={setSelectValue}
					/>
					<label>
						<span>
							Станция:
						</span>
						<select
							value={sStationId}
							onChange={e => setSStationId(e.target.value)}
							{...StationId ? {disabled: true} : null}
						>
							<option value="">{
								sstation.stations.find(a => a.id === StationId)?.name
								||
								'Выберите станцию:'
							}</option>
							{!StationId && sstation.stations.map(a => (
								<option key={a.id} value={a.id}>{a.name}</option>
							))}
						</select>
					</label>
					<button
						type="submit"
						className={button}
						style={{marginTop:"auto"}}
						onClick={AssignTaskHandler}>
						Создать
					</button>
					<style>
						{"input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {-webkit-appearance: none;margin: 0}"}
					</style>
				</form>
			</main>
		</>
		: <header className={authHead}>
			<h2>
				Задача не найдена
			</h2>
		</header>
	)
}

export default AssignTask
