import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import UserStore from './store/UserStore'
import TasksStore from './store/TasksStore'
import TasksMiddleStore from './store/TasksMiddleStore'
import TasksUpStore from './store/TasksUpStore'
import ArticleStore from './store/ArticleStore'
import DepartmentStore from './store/DepartmentStore'
import SHCHUStore from './store/SHCHUStore'
import StationStore from './store/StationStore'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './index.css'

export const Context = createContext(null)

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
	<Context.Provider value={{
		user: new UserStore(),
		tasks: new TasksStore(),
		tasksMidl: new TasksMiddleStore(),
		tasksUp: new TasksUpStore(),
		art: new ArticleStore(),
		dep: new DepartmentStore(),
		sshchu: new SHCHUStore(),
		sstation: new StationStore()
	}}>
		<App/>
	</Context.Provider>,
)

serviceWorkerRegistration.register()

