import React, { useContext, useState } from "react"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button, mTop05 } from "../css/LoginRegister.module.scss"
import { useHistory } from 'react-router-dom'
import { CREATE_TASK_ROUTE } from "../../utils/routeNames"
import { createSHCHU } from "../../http/shchuApi"
import { Context } from "../.."

const AddSHCHU = () => {
	const {sshchu} = useContext(Context)
	const [shchu, setShchu] = useState("")
	const [description, setDescription] = useState("")
	const {push} = useHistory()

	const CreateSHCHUHandler = async () => {
		const [data, id] = await createSHCHU(shchu)
		sshchu.pushSHCHU({id, name: shchu})
		alert(data)
		push(CREATE_TASK_ROUTE)
	}

	return (
		<>
			<header className={authHead}>
				<h2>Создать SHCHU</h2>
			</header>
			<main className={authMain}>
				<form className={formCreate} onSubmit={e=>e.preventDefault()}>
					<label>
						<span>
							Название SHCHU:
						</span>
						<input
							placeholder="Название"
							value={shchu}
							onChange={e=>setShchu(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Описание SHCHU:
						</span>
						<input
							placeholder="Описание"
							value={description}
							onChange={e=>setDescription(e.target.value)}
						/>
					</label>
					<button
						type="submit"
						className={button}
						style={{marginTop:"auto"}}
						onClick={CreateSHCHUHandler}
					>
						Создать SHCHU
					</button>
					<button
						className={button +" "+ mTop05}
						onClick={()=>push(CREATE_TASK_ROUTE)}>
							Отмена
					</button>
				</form>
			</main>
		</>
	)
}

export default AddSHCHU
