import React, { useContext, useState } from "react"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button, mTop05 } from "../css/LoginRegister.module.scss"
import { useHistory } from 'react-router-dom'
import { CREATE_TASK_ROUTE } from "../../utils/routeNames"
import { createStation } from "../../http/stationApi"
import { Context } from "../.."

const AddStation = () => {
	const {sstation, dep} = useContext(Context)
	const [station, setStation] = useState("")
	const [description, setDescription] = useState("")
	const [DepartmentId, setDepartmentId] = useState(null)
	const {push} = useHistory()

	const CreateStationHandler = async () => {
		const [data, id] = await createStation(station, DepartmentId)
		sstation.pushStation({id, name: station, DepartmentId})
		alert(data)
		push(CREATE_TASK_ROUTE)
	}

	return (
		<>
			<header className={authHead}>
				<h2>Создать станцию</h2>
			</header>
			<main className={authMain}>
				<form className={formCreate} onSubmit={e=>e.preventDefault()}>
					<label>
						<span>
							Название станции:
						</span>
						<input
							placeholder="Название"
							value={station}
							onChange={e=>setStation(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Описание станции:
						</span>
						<input
							placeholder="Описание"
							value={description}
							onChange={e=>setDescription(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Цех:
						</span>
						<select
							value={DepartmentId}
							onChange={e => setDepartmentId(e.target.value)}
						>
							<option value="">Выберите цех:</option>
							{dep.departments.map(a => (
								<option key={a.id} value={a.id}>{a.name}</option>
							))}
						</select>
					</label>
					<button
						type="submit"
						className={button}
						style={{marginTop:"auto"}}
						onClick={CreateStationHandler}
					>
						Создать станцию
					</button>
					<button
						className={button +" "+ mTop05}
						onClick={()=>push(CREATE_TASK_ROUTE)}>
							Отмена
					</button>
				</form>
			</main>
		</>
	)
}

export default AddStation
