import {$authHost} from "."

export const createDepartment = async (department, SHCHUDepId) => {
	const {data} = await $authHost.post('api/dep', {department, SHCHUDepId})
	return data
}

export const getDepartments = async () => {
	const {data} = await $authHost.get('api/dep')
	return data
}

export const getDepartment = async (id) => {
	const {data} = await $authHost.get('api/dep/' + id)
	return data
}

export const getUserByDepartment = async (dep) => {
	const {data} = await $authHost.post('api/dep/user', {dep})
	return data
}