import React, { useState, useEffect, useContext } from "react"
import { Context } from ".."
import { getUserByDepartment } from "../http/departmentApi"
import { chooseDepartment, chooseDepartmentUser, active } from "../pages/css/Components.module.scss"

const SearchWorkersInput = ({
	selected,
	setSelected,
	setWorkers,
	radioTag,
	setRadioTag,
	selectValue,
	setSelectValue
}) => {

	const {dep} = useContext(Context)
	const [selectTag, setSelectTag] = useState([])

	const setSelectValueHandler = (e) => {
		setSelectValue(e.target.value)
		getUsersByDepartment(e.target.value)
	}

	useEffect(()=>{
		setSelectTag(dep.departments)
	}, [dep.departments])

	const getUsersByDepartment = (dep) => {
		getUserByDepartment(dep).then(data => {
			setRadioTag(data)
		})
	}

	const toggleWorker = (e, worker) => {
		if(e.target.checked) {
			setSelected([...selected, worker.id])
			setWorkers([...selected, worker.id])
		} else {
			setSelected(selected.filter(item => item !== worker.id))
			setWorkers(selected.filter(item => item !== worker.id))
		}
		e.target.parentElement.classList.toggle(active)
	}

	return (
		<>
			<label>
				<span>
					Работники:
				</span>
				<select
					className={chooseDepartment}
					defaultValue="0"
					value={selectValue}
					onChange={setSelectValueHandler}
				>
					<option disabled value="0" hidden>Цех</option>
					{selectTag.map(item =>
						<option
							key={item.id}
							value={item.id}
						>
							{item.name}
						</option>
					)}
				</select>

			</label>
			<div style={{
				display: "grid",
				gridTemplateColumns: "repeat(auto-fill,minmax(140px,1fr))",
				gap: "10px",
				margin: "10px 0"
			}}>
			{radioTag.map(item =>
				<label
					className={chooseDepartmentUser}
					key={item.id}>
					<input
						type="checkbox"
						name="workers"
						value={item.id}
						onChange={e=>toggleWorker(e, item)}
					/>
					<b style={{margin: '0'}}>{item.username}</b>
				</label>
			)}
			</div>
		</>	)
}

export default SearchWorkersInput
