export const ADMIN_ROLE = 'admin'
export const DISPETCHER_ROLE = 'dispetcher'
export const TEXOTDEL_ROLE = 'texotdel'
export const SHN_ROLE = 'shn'
export const SHNM_ROLE = 'shnm'
export const SHNS_ROLE = 'shns'
export const SHCHU_ROLE = 'shchu'
export const OTDELKADROV_ROLE = 'otdelkadrov'
export const ALL_ROLES_ARRAY = [
	ADMIN_ROLE,
	DISPETCHER_ROLE,
	TEXOTDEL_ROLE,
	SHN_ROLE,
	SHNM_ROLE,
	SHNS_ROLE,
	SHCHU_ROLE,
	OTDELKADROV_ROLE
]
export const UP_ROLES = [
	ADMIN_ROLE,
	SHCHU_ROLE,
	DISPETCHER_ROLE,
	TEXOTDEL_ROLE
]
export const MIDDLE_ROLES = [
	SHNS_ROLE
]
export const DOWN_ROLES = [
	SHN_ROLE,
	SHNM_ROLE
]
export const KADR_ROLES = [
	OTDELKADROV_ROLE,
	ADMIN_ROLE
]
