import React from 'react'
import {observer} from "mobx-react-lite"
import { Link, useLocation } from 'react-router-dom'
import {navFooter, active} from '../pages/css/Navbar.module.scss'
import { CABINET_ROUTE, HOME_ROUTE } from '../utils/routeNames'

const NavBar = observer(() => {
	const just = useLocation().pathname.split("/")[1]
	if(just === "404") return null
	return (
		<footer className={navFooter}>
			<nav>
				<Link className={just === '' ? active: ''} to={HOME_ROUTE}>
					<i className="la la-user-clock la-3x"></i><br/>
					График.
				</Link>
				{/* <Link className={just === 'tasks' ? active: ''} to="/tasks">
					<i className="la la-user-times la-3x"></i><br/>
					Неграфик.
				</Link> */}
				{/* <Link className={just === 'history' ? active: ''} to="/history">
					<i className="la la-history la-3x"></i><br/>
					История
				</Link> */}
				<Link className={just === 'cabinet' ? active: ''} to={CABINET_ROUTE}>
					<i className="la la-user la-3x"></i><br/>
					Профиль
				</Link>
			</nav>
		</footer>
	)
})

export default NavBar
