
import DatePicker from "react-datepicker"
import {parseISO} from "date-fns"
import 'react-datepicker/dist/react-datepicker.css'

const MyDatePicker = ({state, setState, startDate, endDate, minDate, ...props }) => {

	return (
		<DatePicker
			selected={state}
			onChange={(date) => setState(date)}
			startDate={startDate}
			endDate={endDate}
			minDate={minDate}
			maxDate={parseISO(new Date()+ 3.156e+10)}
			dateFormat="d.MM.yyyy"
			placeholderText="Выберите дату"
			onChangeRaw={e => e.preventDefault()}
			onFocus={e => e.target.blur()}
			{...props}
		/>
	)
}

export default MyDatePicker
