import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import {
	depsBtns,
	depsBtn,
	white
} from "../css/HomeUp.module.scss"
import { taskDetails } from "../css/TasksAll.module.scss"
import { NOT_ASSIGNED_TASK_ROUTE } from "../../utils/routeNames"
import { Context } from "../.."

const NotAssignedTasks = () => {
	const {sstation, art, tasksMidl} = useContext(Context)
	const {articles} = art
	const {notAssignedTasks} = tasksMidl
	const [stationId, setStationId] = useState('')

	const changeStyles = e => {
		const plabel = e.target.parentElement
		const plables = plabel.parentElement.querySelectorAll('label')
		plables.forEach(label => {
			label.style.border = 0
			label.style.height = '50px'
		})
		plabel.style.border = '5px solid #fff'
		plabel.style.height = '40px'
	}

	const setStationIdHandler = e => {
		setStationId(e.target.value)
		changeStyles(e)
	}

	return (
		<>
			<header className={authHead}>
				<h2>Не назначенные задачи</h2>
			</header>
			<main className={authMain}>
				<div className={depsBtns}>
					{sstation.stations.map(station =>
						<label
							className={depsBtn+" "+white}
							key={station.id}
							id={station.id}
						>
							<b style={{fontSize: '17px'}}>{station.name}</b>
							<input type="radio" name="radioForDeps" value={station.id} hidden onChange={setStationIdHandler}/>
						</label>
					)}
				</div>
				{notAssignedTasks.filter(task => task.StationId === parseInt(stationId)).map(task => (
					<Link key={task.id} to={NOT_ASSIGNED_TASK_ROUTE + '/' + task.id} className={taskDetails}>
						<div>
							<h3>{articles.find(a=> a.id === task.ArticleId).article}</h3>
						</div>
						<div>
							<span>{task.description}</span>
							<span style={{marginLeft: "auto"}}>Работники: {task.Graphs[0].workers.length}</span>
						</div>
					</Link>
				))}
			</main>
		</>
	)
}

export default NotAssignedTasks
