import { useContext } from 'react'
import { Context } from '..'
import { Link, useHistory } from 'react-router-dom'
import { CHANGE_NUMBER_ROUTE, CHANGE_PASS_ROUTE, CHANGE_ROLE_ROUTE, CHANGE_USER_PASS_ROUTE, FEEDBACK_ROUTE, LOGIN_ROUTE } from '../utils/routeNames'
import { cutting4flex, notKadrButtonExit, kadrButtonExit } from './css/Cabinet.module.scss'
import { authHead, authMain } from './css/AuthPages.module.scss'
import { button, last, margin10 } from './css/LoginRegister.module.scss'
import { ADMIN_ROLE, DISPETCHER_ROLE, KADR_ROLES, OTDELKADROV_ROLE, SHCHU_ROLE, SHNM_ROLE, SHNS_ROLE, SHN_ROLE, TEXOTDEL_ROLE } from '../utils/roleNames'

const Cabinet = () => {
	const {user, tasks, tasksMidl, tasksUp} = useContext(Context)
	const {push} = useHistory()

	const logOut = () => {
		localStorage.removeItem('token')
		tasks.clearAllTasks()
		tasksMidl.clearAllTasks()
		tasksUp.clearAllTasks()
		user.setIsAuth(false)
		user.setUser({})
		push(LOGIN_ROUTE)
		document.body.scrollTop = document.documentElement.scrollTop = 0
	}
	let role

	switch (user.user.role) {
		case ADMIN_ROLE:
			role = 'Администратор'
			break
		case SHN_ROLE:
			role = 'Работник ШН'
			break
		case SHNM_ROLE:
			role = 'Работник ШНМ'
			break
		case SHNS_ROLE:
			role = 'Начальник ШНС'
			break
		case SHCHU_ROLE:
			role = 'Начальник ШЧУ'
			break
		case TEXOTDEL_ROLE:
			role = 'Тех. Отдел'
			break
		case DISPETCHER_ROLE:
			role = 'Диспетчер'
			break
		case OTDELKADROV_ROLE:
			role = 'От. Кадров'
			break
		default:
			role = 'Пидарас'
			break
	}

	return (
		<>
			<header className={authHead}>
				<h2>Личный кабинет</h2>
			</header>
			<main className={authMain}>
				<article>
					<i className="la la-user la-3x"></i>
					<section>
						<strong>Имя Пользователя</strong>
						<div className="cabinetUser text-capitalize">{user.user.username}</div>
					</section>
				</article>
				{user.name ?
					<article>
						<i className="las la-user-cog la-3x"></i>
						<section>
							<strong>Ф.И.О</strong>
							<div className="cabinetUser text-capitalize">{user.name}</div>
						</section>
					</article>
					:
					''
				}
				<article>
					<i className="la la-phone la-3x"></i>
					<section>
						<strong>Номер телефона:</strong>
						{user.user.number.length ?
							<div>{user.user.number}</div>
						:
							<div>
								<Link to={'/my' + CHANGE_NUMBER_ROUTE}>Указать номер телефона
								<i className='la la-pencil'></i></Link>
							</div>
						}
					</section>
				</article>
				<article>
					<i className="la la-shield la-3x"></i>
					<section>
						<strong>Должность:</strong>
						<div>{role}</div>
					</section>
				</article>
				<Link to={FEEDBACK_ROUTE}>
					<button className={button}>Добавить отзыв</button>
				</Link>
				<section className={KADR_ROLES.includes(user.user.role) ? cutting4flex : null}>
					<Link to={CHANGE_PASS_ROUTE}>
						<button className={button +" "+ margin10}>Изменить пароль</button>
					</Link>
					{KADR_ROLES.includes(user.user.role) ? <>
						<Link to={CHANGE_USER_PASS_ROUTE}>
							<button className={button +" "+ margin10}>Изменить пароль пользователя</button>
						</Link>
						<Link to={CHANGE_ROLE_ROUTE}>
							<button className={button +" "+ margin10}>Изменить должность пользователя</button>
						</Link>
						<Link to={CHANGE_NUMBER_ROUTE}>
							<button className={button +" "+ margin10}>Изменить номер пользователя</button>
						</Link>
					</> : ''}
				</section>
				<button
					className={
						last +" "+
						button +" "+
						(
							KADR_ROLES
							.includes(user.user.role) ? kadrButtonExit : notKadrButtonExit
						)
					}
					onClick={logOut}
				>
					Выход
				</button>
			</main>
		</>
	)
}

export default Cabinet
