import Home from './pages/Home'
import Page404 from './pages/404'
import History from './pages/History'
import Cabinet from './pages/Cabinet'
import Login from './pages/Login'
import Task from './pages/Task'
import ChangePass from './pages/ChangePass'
import Tasks from './pages/Tasks'
import CreateTaskChoose from './pages/dispetcher/CreateTaskChoose'
import CreateGraphTaskUp from './pages/dispetcher/CreateGraphTask'
import CreateUnGraphTaskUp from './pages/dispetcher/CreateUnGraphTask'
import CreateGraphTask from './pages/shns/CreateGraphTask'
import CreateUnGraphTask from './pages/shns/CreateUnGraphTask'
import HomeUp from './pages/dispetcher/HomeUp'
import TaskUp from './pages/dispetcher/TaskUp'
import ChangeNumberUp from './pages/admin/ChangeNumberUp'
import ChangePassUp from './pages/admin/ChangePassUp'
import ChangeRoleUp from './pages/admin/ChangeRoleUp'
import ChangeNumber from './pages/ChangeNumber'
import CreateArticle from './pages/admin/CreateArticle'
import CreateDepartment from './pages/admin/CreateDepartment'
import CreateStation from './pages/admin/CreateStation'
import CreateSHCHU from './pages/admin/CreateSHCHU'
import Reason from './pages/Reason'
import ReasonView from './pages/ReasonView'
import CreateUser from './pages/admin/CreateUser'
import NotAssignedTasks from './pages/shns/NotAssignedTasks'
import AssignTask from './pages/shns/AssignTask'
import Feedback from './pages/Feedback'
import MineTasks from './pages/shns/MineTasks'
import MineTaskOne from './pages/shns/MineTaskOne'
import AddVolt from './pages/admin/CreateVolt'

import {
	CABINET_ROUTE,
	HISTORY_ROUTE,
	LOGIN_ROUTE,
	REGISTER_ROUTE,
	HOME_ROUTE,
	PAGE_404_ROUTE,
	TASKS_ROUTE,
	CHANGE_PASS_ROUTE,
	CREATE_TASK_ROUTE,
	CREATE_GRAPH_TASK_ROUTE,
	CREATE_UNGRAPH_TASK_ROUTE,
	CHANGE_ROLE_ROUTE,
	CHANGE_USER_PASS_ROUTE,
	CHANGE_NUMBER_ROUTE,
	CREATE_ARTICLE_ROUTE,
	CREATE_DEPARTMENT_ROUTE,
	REASON_ROUTE,
	REASON_VIEW_ROUTE,
	NOT_ASSIGNED_TASK_ROUTE,
	FEEDBACK_ROUTE,
	MINE_TASKS_SHNS,
	CREATE_SHCHU_ROUTE,
	CREATE_STATION_ROUTE,
	CREATE_VOLT_ROUTE
} from "./utils/routeNames"

export const authRoutes =	[
	{
		path: PAGE_404_ROUTE,
		Component: Page404
	},
	{
		path: CABINET_ROUTE,
		Component: Cabinet
	},
	{
		path: CHANGE_PASS_ROUTE,
		Component: ChangePass
	},
	{
		path: '/my' + CHANGE_NUMBER_ROUTE,
		Component: ChangeNumber
	},
	{
		path: FEEDBACK_ROUTE,
		Component: Feedback
	}
]

export const downRoutes =	[
	{
		path: HOME_ROUTE,
		Component: Home
	},
	{
		path: HISTORY_ROUTE,
		Component: History
	},
	{
		path: TASKS_ROUTE,
		Component: Tasks
	},
	{
		path: TASKS_ROUTE + "/:id",
		Component: Task
	},
	{
		path: REASON_ROUTE + "/:id",
		Component: Reason
	},
	{
		path: REASON_VIEW_ROUTE + "/:id",
		Component: ReasonView
	}
]

export const loginRoutes =	[
	{
		path: LOGIN_ROUTE,
		Component: Login
	}
]

export const upRoutes =	[
	{
		path: HOME_ROUTE,
		Component: HomeUp
	},
	{
		path: CREATE_TASK_ROUTE,
		Component: CreateTaskChoose
	},
	{
		path: CREATE_ARTICLE_ROUTE,
		Component: CreateArticle
	},
	{
		path: CREATE_DEPARTMENT_ROUTE,
		Component: CreateDepartment
	},
	{
		path: CREATE_GRAPH_TASK_ROUTE,
		Component: CreateGraphTaskUp
	},
	{
		path: CREATE_UNGRAPH_TASK_ROUTE,
		Component: CreateUnGraphTaskUp
	},
	{
		path: TASKS_ROUTE + "/:id",
		Component: TaskUp
	},
	{
		path: CHANGE_USER_PASS_ROUTE,
		Component: ChangePassUp
	},
	{
		path: CHANGE_NUMBER_ROUTE,
		Component: ChangeNumberUp
	},
	{
		path: CHANGE_ROLE_ROUTE,
		Component: ChangeRoleUp
	},
	{
		path: REGISTER_ROUTE,
		Component: CreateUser
	}
]

export const middleRoutes =	[
	{
		path: HOME_ROUTE,
		Component: HomeUp
	},
	{
		path: CREATE_TASK_ROUTE,
		Component: CreateTaskChoose
	},
	{
		path: CREATE_ARTICLE_ROUTE,
		Component: CreateArticle
	},
	{
		path: CREATE_DEPARTMENT_ROUTE,
		Component: CreateDepartment
	},
	{
		path: CREATE_GRAPH_TASK_ROUTE,
		Component: CreateGraphTask
	},
	{
		path: CREATE_UNGRAPH_TASK_ROUTE,
		Component: CreateUnGraphTask
	},
	{
		path: TASKS_ROUTE + "/:id",
		Component: TaskUp
	},
	{
		path: CHANGE_USER_PASS_ROUTE,
		Component: ChangePassUp
	},
	{
		path: CHANGE_NUMBER_ROUTE,
		Component: ChangeNumberUp
	},
	{
		path: CHANGE_ROLE_ROUTE,
		Component: ChangeRoleUp
	},
	{
		path: REGISTER_ROUTE,
		Component: CreateUser
	},
	{
		path: NOT_ASSIGNED_TASK_ROUTE,
		Component: NotAssignedTasks
	},
	{
		path: NOT_ASSIGNED_TASK_ROUTE + '/:id',
		Component: AssignTask
	},
	{
		path: MINE_TASKS_SHNS,
		Component: MineTasks
	},
	{
		path: MINE_TASKS_SHNS + '/:id',
		Component: MineTaskOne
	},
	{
		path: REASON_ROUTE + "/:id",
		Component: Reason
	},
	{
		path: REASON_VIEW_ROUTE + "/:id",
		Component: ReasonView
	}
]

export const kadrRoutes =	[
	{
		path: HOME_ROUTE,
		Component: HomeUp
	},
	{
		path: CREATE_TASK_ROUTE,
		Component: CreateTaskChoose
	},
	{
		path: CREATE_ARTICLE_ROUTE,
		Component: CreateArticle
	},
	{
		path: CREATE_SHCHU_ROUTE,
		Component: CreateSHCHU
	},
	{
		path: CREATE_DEPARTMENT_ROUTE,
		Component: CreateDepartment
	},
	{
		path: CREATE_STATION_ROUTE,
		Component: CreateStation
	},
	{
		path: CREATE_VOLT_ROUTE,
		Component: AddVolt
	},
	{
		path: CHANGE_USER_PASS_ROUTE,
		Component: ChangePassUp
	},
	{
		path: CHANGE_NUMBER_ROUTE,
		Component: ChangeNumberUp
	},
	{
		path: CHANGE_ROLE_ROUTE,
		Component: ChangeRoleUp
	},
	{
		path: REGISTER_ROUTE,
		Component: CreateUser
	}
]

// export const publicRoutes =	[

// ]