import React, { useContext, useState } from "react"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button, mTop05 } from "../css/LoginRegister.module.scss"
import { useHistory } from 'react-router-dom'
import { CREATE_TASK_ROUTE } from "../../utils/routeNames"
import { createDepartment } from "../../http/departmentApi"
import { Context } from "../.."

const AddDepartment = () => {
	const {dep, sshchu} = useContext(Context)
	const [department, setDepartment] = useState("")
	const [SHCHUDepId, setSHCHUDepId] = useState(null)
	const [description, setDescription] = useState("")
	const {push} = useHistory()

	const CreateDepartmentHandler = async () => {
		const [data, id] = await createDepartment(department, SHCHUDepId)
		dep.pushDepartment({id, name: department, SHCHUDepId})
		alert(data)
		push(CREATE_TASK_ROUTE)
	}

	return (
		<>
			<header className={authHead}>
				<h2>Создать цех</h2>
			</header>
			<main className={authMain}>
				<form className={formCreate} onSubmit={e=>e.preventDefault()}>
					<label>
						<span>
							Название цеха:
						</span>
						<input
							placeholder="Название"
							value={department}
							onChange={e=>setDepartment(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Описание цеха:
						</span>
						<input
							placeholder="Описание"
							value={description}
							onChange={e=>setDescription(e.target.value)}
						/>
					</label>
					<label>
						<span>
							SHCHU:
						</span>
						<select
							value={SHCHUDepId}
							onChange={e => setSHCHUDepId(e.target.value)}
						>
							<option value="">Выберите SHCHU</option>
							{sshchu.shchus.map(a => (
								<option key={a.id} value={a.id}>{a.name}</option>
							))}
						</select>
					</label>
					<button
						type="submit"
						className={button}
						style={{marginTop:"auto"}}
						onClick={CreateDepartmentHandler}
					>
						Создать цех
					</button>
					<button
						className={button +" "+ mTop05}
						onClick={()=>push(CREATE_TASK_ROUTE)}>
							Отмена
					</button>
				</form>
			</main>
		</>
	)
}

export default AddDepartment
