import React, { useContext, useState } from "react"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button, mTop05 } from "../css/LoginRegister.module.scss"
import { useHistory } from 'react-router-dom'
import { CREATE_TASK_ROUTE } from "../../utils/routeNames"
import { createVolt } from "../../http/checkVoltApi"
import { Context } from "../.."

const AddVolt = () => {
	const {sstation, art} = useContext(Context)
	const [name, setName] = useState("")
	const [norma1, setNorma1] = useState("")
	const [norma2, setNorma2] = useState("")
	const [times, setTimes] = useState(null)
	const [StationId, setStationId] = useState("")
	const [ArticleId, setArticleId] = useState("")
	const {push} = useHistory()

	const CreateVoltHandler = async () => {
		const data = await createVolt(name, norma1+'-'+norma2, times, StationId, ArticleId)
		alert(data)
		push(CREATE_TASK_ROUTE)
	}

	return (
		<>
			<header className={authHead}>
				<h2>Создать Volt</h2>
			</header>
			<main className={authMain}>
				<form className={formCreate} onSubmit={e=>e.preventDefault()}>
					<label>
						<span>
							Название Volt:
						</span>
						<input
							placeholder="Название"
							value={name}
							onChange={e=>setName(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Норма:
						</span>
						<div style={{display: "flex"}}>
							<input
								placeholder="Норма"
								value={norma1}
								onChange={e=>setNorma1(e.target.value)}
							/>
							<input
								placeholder="Норма"
								value={norma2}
								onChange={e=>setNorma2(e.target.value)}
							/>
						</div>
					</label>
					<label>
						<span>
							Период:
						</span>
						<div style={{display: "flex"}}>
							<input
								type="number"
								placeholder="Время"
								value={times}
								onChange={e=>setTimes(e.target.value)}
							/>
							<p style={{width: "6.25rem"}}>раза в день</p>
						</div>
					</label>
					<label>
						<span>
							Станция:
						</span>
						<select
							value={StationId}
							defaultValue=""
							onChange={e => setStationId(e.target.value)}
						>
							<option value="">Выберите станцию:</option>
							{sstation.stations.map(a => (
								<option key={a.id} value={a.id}>{a.name}</option>
							))}
						</select>
					</label>
					<label>
						<span>
							Задача:
						</span>
						<select
							value={ArticleId}
							defaultValue=""
							onChange={e => setArticleId(e.target.value)}
						>
							<option value="">Выберите задачу:</option>
							{art.articles.map(a => (
								<option key={a.id} value={a.id}>{a.article}</option>
							))}
						</select>
					</label>
					<button
						type="submit"
						className={button}
						style={{marginTop:"auto"}}
						onClick={CreateVoltHandler}
					>
						Создать Volt
					</button>
					<button
						className={button +" "+ mTop05}
						onClick={()=>push(CREATE_TASK_ROUTE)}>
							Отмена
					</button>
				</form>
			</main>
		</>
	)
}

export default AddVolt
