import {makeAutoObservable} from "mobx"

export default class TasksUpStore {
	constructor() {
		this._activeTasks = []
		this._deletedTasks = []
		this._all = []
		makeAutoObservable(this)
	}


	_checkPush(task, array) {
		if(task?.constructor.name === 'Array') {
			array.push(...task)
		} else if(task?.constructor.name === 'Object') {
			array.push(task)
		}
	}

	setActiveTasks(activeTasks) {
		this._checkPush(activeTasks, this._activeTasks)
	}

	setDeletedTasks(deletedTasks) {
		this._checkPush(deletedTasks, this._deletedTasks)
	}

	setAll() {
		this._checkPush(this._activeTasks, this._all)
		this._checkPush(this._deletedTasks, this._all)
	}

	clearAllTasks() {
		this._activeTasks = []
		this._deletedTasks = []
	}

	get activeTasks() {
		return this._activeTasks
	}
	get deletedTasks() {
		return this._deletedTasks
	}
	get all() {
		return this._all
	}
}