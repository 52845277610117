import React, { useState, useContext } from "react"
import MyDatePicker from "../../components/MyDatePicker"
import { Context } from '../..'
import { createUnGraphTask } from "../../http/tasksUpApi"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button } from "../css/LoginRegister.module.scss"

const CreateTask = () => {
	const { tasksUp, dep } = useContext(Context)
	const [article, setArticle] = useState("")
	const [description, setDescription] = useState("")
	const [startDate, setStartDate] = useState("")
	const [endDate, setEndDate] = useState("")
	const [location, setLocation] = useState([54.5454, 56.5656]) //eslint-disable-line
	const [departmentId, setDepartmentId] = useState("")
	const deadline = [startDate, endDate]

	const createUnGraphTaskHandler = async () => {

		if(article?.length && description?.length !== 0 && departmentId !== '') {
			await createUnGraphTask(article, description, location, deadline, departmentId)
			.then(d => {
				setArticle("")
				setDescription("")
				setStartDate()
				setEndDate()
				tasksUp.setNewTasks(d)
			})
			.catch(e=>console.log(e.response.data.message))
		} else {
			alert("Заполните все поля")
		}
	}

	

	return (
		<>
		<header className={authHead}>
			<h2>Создать неграфическую задачу</h2>
		</header>
		<main className={authMain}>
			<form className={formCreate} onSubmit={e=>e.preventDefault()}>
				<label>
					<span>
						Название задачи:
					</span>
					<input
						placeholder="Название"
						value={article}
						onChange={e=>setArticle(e.target.value)}
					/>
				</label>
				<label>
					<span>
						Описание задачи:
					</span>
					<input
						placeholder="Описание"
						value={description}
						onChange={e=>setDescription(e.target.value)}
					/>
				</label>
				<label>
					<span>
						Дата начала:
					</span>
					<MyDatePicker
						state={startDate}
						setState={setStartDate}
						startDate={startDate}
						endDate={endDate}
						minDate={new Date()}
						selectsStart
					/>
				</label>
				<label>
					<span>
						Дата окончания:
					</span>
					<MyDatePicker
						state={endDate}
						setState={setEndDate}
						startDate={startDate}
						endDate={endDate}
						minDate={startDate}
						selectsEnd
					/>
				</label>
				<label>
					<span>
						Выбрать цех:
					</span>
					<select value={departmentId} onChange={e=>setDepartmentId(e.target.value)}>
						<option value={''}>Выберите цех:</option>
						{dep.departments.map(a=>(
							<option key={a.id} value={a.id}>{a.name}</option>
						))}
					</select>
				</label>
				<button
					type="submit"
					className={button}
					style={{marginTop:"auto"}}
					onClick={createUnGraphTaskHandler}>
					Создать
				</button>
			</form>
		</main>
		</>
	)
}

export default CreateTask
