import React from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { upRoutes, authRoutes, downRoutes, loginRoutes, middleRoutes, kadrRoutes } from '../routes'
import { DOWN_ROLES, MIDDLE_ROLES, UP_ROLES, KADR_ROLES } from '../utils/roleNames'
import { PAGE_404_ROUTE, LOGIN_ROUTE } from '../utils/routeNames'

const AppRouter = ({role, isAuth}) => {
	const {pathname} = useLocation()
	return (
		<Switch>
			{!isAuth && loginRoutes.map(({path, Component}) =>
				<Route key={path} path={path} component={ Component } exact/>
			)}
			{isAuth && authRoutes.map(({path, Component}) =>
				<Route key={path} path={path} component={ Component } exact/>
			)}
			{isAuth && DOWN_ROLES.includes(role) && downRoutes.map(({path, Component}) =>
				<Route key={path} path={path} component={ Component } exact/>
			)}
			{isAuth && MIDDLE_ROLES.includes(role) && middleRoutes.map(({path, Component}) =>
				<Route key={path} path={path} component={ Component } exact/>
			)}
			{isAuth && UP_ROLES.includes(role) && upRoutes.map(({path, Component}) =>
				<Route key={path} path={path} component={ Component } exact/>
			)}
			{isAuth && KADR_ROLES.includes(role) && kadrRoutes.map(({path, Component}) =>
				<Route key={path} path={path} component={ Component } exact/>
			)}
			<Redirect to={isAuth ? PAGE_404_ROUTE : LOGIN_ROUTE+"?next="+pathname}/>
		</Switch>
	)
}

export default AppRouter