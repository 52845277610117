import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import SearchWorkerInput from "../../components/SearchWorkerInput"
import { updatePassUp } from "../../http/userUpAPI"
import { CABINET_ROUTE } from "../../utils/routeNames"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button, mTop05 } from "../css/LoginRegister.module.scss"

const ChangePassUp = () => {
	const [worker, setWorker] = useState({})
	const [pass, setPass] = useState("")
	const [passDirty, setPassDirty] = useState(false)
	const [passErr, setPassErr] = useState(true)
	const [formValid, setFormValid] = useState(false)
	const {push} = useHistory()

	const blurHandler = () => {
		setPassDirty(true)
	}

	const passHandler = (e) => {
		setPass(e.target.value)
		if(e.target.value.length < 8 || e.target.value.length > 24){
			setPassErr('Пароль неверный')
		} else {
			setPassErr()
		}
	}
	
	useEffect(() => {
		if(passErr || !worker?.id){
			setFormValid(false)
		} else {
			setFormValid(true)
		}
	}, [passErr, worker])


	const ChangePassUpHandler = async () => {
		try {
			await updatePassUp(worker.id, pass)
			.then(data => alert(data))
			push(CABINET_ROUTE)
		} catch (e) {
			alert(e.response.data.message)
		}
	}


	return (
		<>
		<header className={authHead}>
			<h2>Изменить пароль пользователя</h2>
		</header>
		<main className={authMain}>
			<form className={formCreate} onSubmit={e=>e.preventDefault()}>
				
				<SearchWorkerInput
					setWorker={setWorker}
					root={true}
				/>
				<b>{worker?.username}</b>
				<label>
					<span>
						Пароль:
					</span>
					<input
						type="password"
						placeholder="Пароль:"
						value={pass}
						onChange={passHandler}
						onBlur={blurHandler}
					/>
				</label>
				{(passDirty && passErr) && <abs>{passErr}</abs>}
				<button
					type="submit"
					className={button}
					style={{marginTop:"auto"}}
					disabled={!formValid}
					onClick={ChangePassUpHandler}
				>
					Изменить пароль
				</button>
				<button
					className={button +" "+ mTop05}
					onClick={()=>push(CABINET_ROUTE)}>
						Отмена
				</button>
			</form>
		</main>
		</>
	)
}

export default ChangePassUp
