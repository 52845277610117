import {$authHost} from "."

export const registerUser = async (
	number,
	password,
	username,
	firstname,
	middlename,
	lastname,
	birthday,
	StationId,
	DepartmentId,
	SHCHUDepId,
	role
	) => {
	const {data} = await $authHost.post(
		'api/user/register', {
			number,
			password,
			username,
			firstname,
			middlename,
			lastname,
			birthday,
			StationId,
			DepartmentId,
			SHCHUDepId,
			role
		}
	)
	return data
}

export const updateNumberUp = async (id, number) => {
	const {data} = await $authHost.put('api/user/update/number', {id, number})
	return data
}

export const updateRoleUp = async (id, newRole) => {
	const {data} = await $authHost.post('api/user/update/role', {id, newRole})
	return data
}

export const updatePassUp = async (id, newPass) => {
	const {data} = await $authHost.put('api/user/update/pass', {id, newPass})
	return data
}