import {makeAutoObservable} from "mobx"

export default class StationStore {
	constructor() {
		this._stations = []
		makeAutoObservable(this)
	}

	pushStation(station) {
		this._stations = [ ...this._stations, station]
	}

	setStations(stations) {
		this._stations = stations
	}

	get stations() {
		return this._stations
	}
}