import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { taskDetails } from "../css/TasksAll.module.scss"
import { MINE_TASKS_SHNS } from "../../utils/routeNames"
import { Context } from "../.."
import {
	depsBtns,
	depsBtn,
	white
} from "../css/HomeUp.module.scss"

const MineTasks = () => {
	const {tasksMidl, sstation} = useContext(Context)
	const {mineTasks} = tasksMidl
	const [stationId, setStationId] = useState('')

	const changeStyles = e => {
		const plabel = e.target.parentElement
		const plables = plabel.parentElement.querySelectorAll('label')
		plables.forEach(label => {
			label.style.border = 0
			label.style.height = '50px'
		})
		plabel.style.border = '5px solid #fff'
		plabel.style.height = '40px'
	}

	const setStationIdHandler = e => {
		setStationId(e.target.value)
		changeStyles(e)
	}

	const stations = () => {
		const arr = []
		for (let tsk of mineTasks) {
			arr.push(tsk.StationId)
		}
		return sstation.stations.filter(station => arr.includes(station.id))
	}

	return (
		<>
			<header className={authHead}>
				<h2>Задачи</h2>
			</header>
			<main className={authMain}>
				<div className={depsBtns}>
					{stations().map(station =>
						<label
							className={depsBtn+" "+white}
							key={station.id}
							id={station.id}
						>
							<b style={{fontSize: '17px'}}>{station.name}</b>
							<input type="radio" name="radioForDeps" value={station.id} hidden onChange={setStationIdHandler}/>
						</label>
					)}
				</div>
				{mineTasks.filter(task => task.StationId === parseInt(stationId)).map(task => (
					<Link key={task.id} to={MINE_TASKS_SHNS + '/' + task.id} className={taskDetails}>
						<div>
							<h3>{task.Article.article}</h3>
						</div>
						<div>
							<span>{task.description}</span>
							<span style={{marginLeft: "auto"}}>Работники: {task.Graphs[0].workers.length}</span>
						</div>
					</Link>
				))}
			</main>
		</>
	)
}

export default MineTasks
