import React, {
	useState,
	useContext,
	useEffect,
	useRef
} from "react"
import { authHead, authMain } from './css/AuthPages.module.scss'
import {
	createReason,
	createStartDU46,
	addFinishDU46
} from '../http/reasonApi'
import { createCheckVolt } from '../http/checkVoltApi'
import { useParams, useHistory } from "react-router-dom"
import { Context } from ".."
import { button } from "./css/LoginRegister.module.scss"
import { photosFigure } from "./css/HomeUp.module.scss"
import { iCheck, du46figure, voltOne, imgDetails } from "./css/Reason.module.scss"
import { SHNS_ROLE } from "../utils/roleNames"
import { REASON_VIEW_ROUTE } from "../utils/routeNames"
import Compressor from 'compressorjs'

const Reason = () => {
	const {id} = useParams()
	const {user, tasks, tasksMidl} = useContext(Context)
	const Task = user.user.role === SHNS_ROLE ? tasksMidl : tasks
	const task = (
		user.user.role === SHNS_ROLE ?
		tasksMidl.mineTasks
		:
		tasks.activeTasks
	).filter(t=> t.id === parseInt(id))[0]
	const buttonSubmit = useRef()
	const [isCompleted, setIsCompleted] = useState(false)
	const [reason, setReason] = useState("")
	const [progress, setProgress] = useState()
	const [photos, setPhotos] = useState([])
	const [DU46startPhoto, setDU46startPhoto] = useState("")
	const [DU46finishPhoto, setDU46finishPhoto] = useState("")
	const [taskDU46, setTaskDU46] = useState({})
	const [volts, setVolts] = useState([])
	const [voltValues, setVoltValues] = useState([])
	const [voltNullValues, setVoltNullValues] = useState([])
	const {push} = useHistory()

	useEffect(() => {
		if((isCompleted && photos.length) || (!isCompleted && reason.length>6)) {
			buttonSubmit.current.disabled = false
		} else {
			buttonSubmit.current.disabled = true
		}
	}, [isCompleted, photos, reason])

	useEffect(() => {
		const getDU46s = async () => {
			try {
				task.addons.includes('du46') &&
				setTaskDU46(task.Graphs[0].DU46)
			} catch (e) {
				alert(e.response.data.message)
			}
		}
		getDU46s()
	}, [id, task])

	useEffect(() => {
		const getVoltsAll = async () => {
			try {
				task.addons.includes('volt') &&
				setVolts(task.Volts)
			} catch (e) {
				alert(e.response.data.message)
			}
		}
		getVoltsAll()
	}, [id, task])

	useEffect(() => {
		const getVoltsAll = async () => {
			try {
				task.addons.includes('volt') &&
				setVoltValues(task.Graphs[0].CheckVolts)
			} catch (e) {
				alert(e.response.data.message)
			}
		}
		getVoltsAll()
	}, [id, task])
	
	useEffect(() => {
		setVoltNullValues(voltValues.filter(v=> !v.id))
	}, [voltValues])

	const createReasonHandler = async () => {
		try {
			for (const photo of photos) {
				if(
					new Date(photo.lastModifiedDate)
					<
					new Date(new Date().toDateString())) {
					return alert('Это фото не подлежит')
				}
			}

			buttonSubmit.current.disabled = true
			const reasonForm = new FormData()

			reasonForm.append("isCompleted", isCompleted)
			reasonForm.append("reason", reason)
			const promises = []
			for (const photo of photos) {
				promises.push(new Promise(function (resolve, reject) {
					new Compressor(photo, {
						quality: 0.2,
						success(result) {
							reasonForm.append('photos', result, photo.lastModified+'.'+photo.name.split('.').pop())
							resolve()
						},
						error(err) {
							console.log(err.message)
							reject()
						},
					})
				}))
			}

			Promise.all(promises).then(async function () {
				await createReason(reasonForm, task.Graphs[0].id, setProgress).then(res => {
					Task.addActiveReason(id, res)
					alert("Задача принята")
					push(REASON_VIEW_ROUTE + '/' + id)
				})
			})
		} catch (e) {
			alert(e.response.data.message)
		}
	}
	const onPhotoAddedHandler = (e) => {
		setPhotos([...photos, ...e.target.files])
	}

	const rmPhotoHandler = (e) => {
		setPhotos(
			photos.filter(photo =>
				photo.lastModified !== parseInt(e.target.parentElement.attributes['name'].value)
			)
		)
	}

	const addDU46startHandler = async () => {
		try {
			const du46form = new FormData()
			const promises = []
			promises.push(new Promise(function (resolve, reject) {
				new Compressor(DU46startPhoto, {
					quality: 0.2,
					success(result) {
						du46form.append(
							'start',
							result,
							DU46startPhoto.lastModified+'.'+DU46startPhoto.name.split('.').pop()
						)
						du46form.append("GraphId", task.Graphs[0].id)
						resolve()
					},
					error(err) {
						console.log(err.message)
						reject()
					}
				})
			}))

			Promise.all(promises).then(async function () {
				await createStartDU46(du46form, id, setProgress).then(res => {
					alert(res)
				})
			})
			setTaskDU46({
				start: DU46startPhoto,
				id: (Math.random()*10).toFixed(),
				GraphId: parseInt(id),
				finish: null
			})
		} catch (e) {
			alert(e.response.data.message)
		}
	}

	const addDU46finishHandler = async () => {
		try {
			const du46form = new FormData()
			const promises = []
			promises.push(new Promise(function (resolve, reject) {
				new Compressor(DU46finishPhoto, {
					quality: 0.2,
					success(result) {
						du46form.append(
							'finish',
							result,
							DU46finishPhoto.lastModified+'.'+DU46finishPhoto.name.split('.').pop()
						)
						du46form.append("GraphId", task.Graphs[0].id)
						resolve()
					},
					error(err) {
						console.log(err.message)
						reject()
					}
				})
			}))

			Promise.all(promises).then(async function () {
				await addFinishDU46(du46form, id, setProgress).then(res => {
					alert(res)
				})
			})
			setTaskDU46({...taskDU46, finish: DU46finishPhoto})
		} catch (e) {
			alert(e.response.data.message)
		}
	}

	const createCheckVoltHandler = async () => {
		try {
			await createCheckVolt(id, voltNullValues)
			.then(data=>{
				setVoltNullValues(voltNullValues.map((v, i) => {
					return {
					...voltNullValues[i],
					id: v.id = Math.random() * 100
					}
				}))
				alert(data)
			})
		} catch (e) {
			alert(e.response.data.message)
		}
	}

	const checkVoltNorma = (norma, e) => norma.reduce((a, b) => {
		if(
			parseInt(a)<=parseInt(e.target.value)
			&&
			parseInt(e.target.value)<=parseInt(b)
		) {
			return 0
		} else if(parseInt(a)>parseInt(e.target.value)) {
			return 1
		} else if(parseInt(b)<parseInt(e.target.value)) {
			return 2
		}
		return 3
	})

	const setVoltValuesHandler = (e, VoltId, GraphId, norma, time) => {
		const arr = [...voltValues]
		const obj = {
			VoltId,
			GraphId,
			volt: e.target.value,
			status: checkVoltNorma(norma, e),
			time
		}

		if(arr.length) {
			const b = arr.find(a=>a.VoltId === VoltId && time === a.time)
			if(b) {
				b.volt = e.target.value
				b.status = checkVoltNorma(norma, e)
			} else {
				arr.push(obj)
			}
		} else {
			arr.push(obj)
		}
		const filtered = arr.filter(a=> a.status !== 3)
		setVoltValues(filtered)
	}

	const Iterate = (array1) => {
		let idIdx = 1
		const res = array1.flatMap(obj => {
			let arr = []
			for (let i = 0; i < obj.times; i++) {
				arr.push({
					id: idIdx++,
					VoltId: obj.id,
					time: i + 1,
					name: obj.name,
					norma: obj.norma,
					voltValues: voltValues.filter(a=> a.VoltId === obj.id)
				})
			}
			return arr
		})
		return res.sort((a, b) => a.time - b.time)
	}

	const voltTime = (time) => {
		if(time === 1) return 'Утренный'
		if(time === 2) return 'Вечерный'
		if(time === 3) return 'Другое'
	}

	return (
		<>
			<header className={authHead}>
				<h2>Решение</h2>
			</header>
			<main className={authMain}>
				<div style={{display: "flex"}}>Статус задачи:
					{isCompleted ? ' Выполнено' : ' Не выполнено'}
					<input
						type="checkbox"
						checked={isCompleted}
						value={isCompleted}
						className={iCheck}
						onChange={() => setIsCompleted(!isCompleted)}
					/>
				</div>
				{isCompleted ?
					<>
						<label>
							Решение:&nbsp;
							<br/>
							<i className="las la-5x la-plus-square"></i>
							<input
								type="file"
								accept="image/*"
								capture="environment"
								multiple
								hidden
								onChange={onPhotoAddedHandler}
							/>
						</label>
						<figure className={photosFigure}>
							{[...photos]
								.map(photo =>
									<div className={imgDetails} key={photo.lastModified} name={photo.lastModified}>
										<i className="las la-3x la-trash" onClick={rmPhotoHandler}></i>
										<img
											src={URL.createObjectURL(photo)}
											alt={`Фото задачи №${id}`}
										/>
									</div>
								)
							}
						</figure>
					</>
				:
					<label>
						Причина:&nbsp;
						<input
							type="text"
							value={reason}
							onChange={e => setReason(e.target.value)}
						/>
					</label>
				}

				{
					task.addons.includes('du46') && (
						<figure className={du46figure}>
							<h3>ДУ46</h3>
							<div>
								<label>
									<p>Начало</p>
									{taskDU46?.start ?
										<img src={'/img/'+taskDU46.start} alt="Фото DU46" />
										:
										<>
											<input
												type="file"
												accept="image/*"
												capture="environment"
												onChange={e=>setDU46startPhoto(e.target.files[0])}
												style={{marginLeft: 'auto'}}
											/>
											{DU46startPhoto &&<button onClick={addDU46startHandler}>Отправить</button>}
										</>
									}
								</label>
								<label>
									{taskDU46?.start &&
									<>
										<p>Конец</p>
										{!!taskDU46?.finish ?
											<img src={'/img/'+taskDU46.finish} alt="Фото DU46" />
											:
											<>
												<input
													type="file"
													accept="image/*"
													capture="environment"
													onChange={e=>setDU46finishPhoto(e.target.files[0])}
													style={{marginLeft: 'auto'}}
												/>
												{DU46finishPhoto &&<button onClick={addDU46finishHandler}>Отправить</button>}
											</>
										}
									</>
									}
								</label>
							</div>
						</figure>
					)
				}
				{
					task.addons.includes('volt') &&
					<figure className={du46figure}>
						<h3>Измерения</h3>
						<div className={voltOne}>
							{Iterate(volts).map(volt=>
								<div key={volt.id}>
									<b>{volt.name}</b>
									<p>{voltTime(volt.time)}</p>
									<input
										value={
											voltValues.filter(a=>
												a.VoltId === volt.VoltId &&
												a.time === volt.time
											)[0]?.volt
										}
										{...voltValues.filter(a=>
												a.VoltId === volt.VoltId &&
												a.time === volt.time
											)[0]?.id
											&&
											{ disabled: true }
										}
										onChange={e =>
											setVoltValuesHandler(
												e,
												volt.VoltId,
												task.Graphs[0].id,
												volt.norma.split('-'),
												volt.time
											)
										}
									/>
									<p>{volt.norma}</p>
								</div>
							)}
						</div>
						{
							voltNullValues.length
							?
								<button onClick={createCheckVoltHandler}>Отправить</button>
							:
								null
						}
					</figure>
				}
				
				<input type="range"
					value={progress || 0}
					style={{marginTop:"auto"}}
					readOnly
				/>
				<button
					type="submit"
					className={button}
					style={{marginTop:".5rem"}}
					onClick={createReasonHandler}
					ref={buttonSubmit}
					>
					Выполнить
				</button>
				
			</main>
		</>
	)
}

export default Reason
