import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { updatePassword } from '../http/userAPI'
import { CABINET_ROUTE } from '../utils/routeNames'
import { loginForm, button, last, notMB, mTop05 } from './css/LoginRegister.module.scss'

const ModalPasswordForm = () => {
	const {push} = useHistory()
	const [oldPassword, setOldPassword] = useState("")
	const [password, setPassword] = useState("")
	const [cPassword, setCPassword] = useState("")
	const [oldPasswordDirty, setOldPasswordDirty] = useState(false)
	const [passwordDirty, setPasswordDirty] = useState(false)
	const [cPasswordDirty, setCPasswordDirty] = useState(false)
	const [oldPasswordErr, setOldPasswordErr] = useState(true)
	const [passwordErr, setPasswordErr] = useState(true)
	const [cPasswordErr, setCPasswordErr] = useState(true)
	const [match, setMatch] = useState(false)
	const [formValid, setFormValid] = useState(false)

	const blurHandler = (e) => {
		switch (e.target.name) {
			case 'oldPassword':
				setOldPasswordDirty(true)
				break
		
			case 'password':
				setPasswordDirty(true)
				break
				
			case 'cPassword':
				setCPasswordDirty(true)
				break
			default:
				break
		}
	}

	const oldPassHandler = e => {
		setOldPassword(e.target.value)
		if(e.target.value.length < 8 || e.target.value.length > 24 || !e.target.value) {
			setOldPasswordErr('Пароль неверный')
		} else {
			setOldPasswordErr()
		}
	}
	
	const passHandler = e => {
		setPassword(e.target.value)
		if(e.target.value.length < 8 || e.target.value.length > 24 || !e.target.value) {
			setPasswordErr('Пароль неверный')
		} else {
			setPasswordErr()
		}
	}
	const cPassHandler = e => {
		setCPassword(e.target.value)
	}

	useEffect(() => {
		setMatch(password && password === cPassword)
		if(!match) {
			setCPasswordErr('Пароли не совпадают')
		} else {
			setCPasswordErr()
		}
	}, [password, cPassword, match])

	const submitPass = async () => {
		if(!oldPassword || !password || !cPassword)
		return alert('Не хватает данные!')
		try {
			await updatePassword(oldPassword, password)
			alert('Успешно изменено!')
			push(CABINET_ROUTE)
		} catch (e) {
			alert(e.response.data.message)
		}
		
	}

	useEffect(() => {
		if(oldPasswordErr || passwordErr || cPasswordErr) {
			setFormValid(false)
		} else {
			setFormValid(true)
		}
	}, [oldPasswordErr, passwordErr, cPasswordErr])

	return (
		<>
			<form className={loginForm} onSubmit={e => e.preventDefault()}>
				<header>
					<h2>Забыли пароль?</h2>
				</header>
				<main className={notMB}>
					<label>
						<div>
							<span><abs>{(oldPasswordDirty && oldPasswordErr) && oldPasswordErr}</abs></span>
							<input type="password" className="w-100 formInput mb-3"
								autoComplete="current-password" required
								placeholder="Старый пароль..."
								name="oldPassword"
								value={oldPassword}
								onChange={e => oldPassHandler(e)}
								onBlur={e => blurHandler(e)}
							/>
						</div>
					</label>
					<label>
						<div>
							<span><abs>{(passwordDirty && passwordErr) && passwordErr}</abs></span>
							<input type="password" className="w-100 formInput mb-3" id='pass1'
								autoComplete="new-password0" required
								placeholder="Новый пароль..."
								name="password"
								value={password}
								onChange={e => passHandler(e)}
								onBlur={e => blurHandler(e)}
							/>
						</div>
					</label>
					<label>
						<div>
							<span><abs>{(cPasswordDirty && cPasswordErr) && cPasswordErr}</abs></span>
							<input type="password" className="w-100 formInput mb-3" id='pass2'
								autoComplete="new-password1" required
								placeholder="Подтвердите пароль..."
								name="cPassword"
								value={cPassword}
								onChange={e => cPassHandler(e)}
								onBlur={e => blurHandler(e)}
							/>
							
						</div>
					</label>
					<button
						className={button +" "+ last}
						type="submit"
						onClick={submitPass}
						disabled={!formValid}>
							Изменить пароль
					</button>
					<button
						className={button +" "+ mTop05}
						onClick={()=>push(CABINET_ROUTE)}>
							Отмена
					</button>
				</main>
			</form>
		</>
	)
}

export default ModalPasswordForm
