import React, { useContext } from "react"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { taskDetails } from "../css/TasksAll.module.scss"
import { Context } from "../.."
import { useParams } from "react-router-dom"
import {
	SHNS_ROLE
} from '../../utils/roleNames'

const TaskUp = () => {
	const {user, tasksUp, tasksMidl} = useContext(Context)
	const Task = user.user.role === SHNS_ROLE ? tasksMidl : tasksUp
	const {id} = useParams()

	const task = Task.all.filter(task => task.id == id)[0] //eslint-disable-line
	return (
		task ?
		<>
			<header className={authHead}>
				<h2>Задача № {task.id}</h2>
			</header>
			<main className={authMain +" "+ taskDetails}>
				<div>Описание: <b>{task.description}</b></div>
				<div>Статус: <b>{task.status}</b></div>

				
			</main>
		</>
		:
		<header className={authHead}>
			<h2>
				Задача не найдена
			</h2>
		</header>
	)
}

export default TaskUp
