import React, { useState, useContext } from "react"
import MyDatePicker from "../../components/MyDatePicker"
import { Context } from '../..'
import { createGraphTask } from "../../http/tasksUpApi"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button } from "../css/LoginRegister.module.scss"
import { iCheck } from "../css/Reason.module.scss"

const CreateTask = () => {
	const { tasksUp, art, dep, sstation } = useContext(Context)
	const [article, setArticle] = useState("")
	const [description, setDescription] = useState("")
	const [date, setDate] = useState("")
	const [location, setLocation] = useState([41.311081, 69.240562]) //eslint-disable-line
	const [repeatType, setRepeatType] = useState(1)
	const [repeatDay, setRepeatDay] = useState("")
	const [departmentId, setDepartmentId] = useState("")
	const [StationId, setStationId] = useState("")
	const [du46val, setDU46Val] = useState(false)
	const [checkVoltval, setCheckVoltVal] = useState(false)

	const addons = []
	if(du46val) addons.push('du46')
	if(checkVoltval) addons.push('volt')

	const createGraphTaskHandler = async () => {

		if(article?.length && description?.length !== 0 && departmentId !== '') {
			await createGraphTask(
				article,
				description,
				location,
				repeatDay * repeatType,
				date,
				addons,
				departmentId,
				StationId
			)
			.then(d => {
				tasksUp.setActiveTasks({
					...d[0], Graphs : [{
						...d[1], GraphReason: null
					}]
				})
				setArticle("")
				setDescription("")
				setDate("")
				setRepeatDay("")
				setRepeatType(1)
				setDepartmentId("")
				setStationId("")
				alert("Задача успешно создана")
			})
			.catch(e=>console.log(e.response.data.message))
		} else {
			alert("Заполните все поля")
		}
	}

	let sortedArticles = art.articles.map(a =>
		[a.article.toLowerCase().replace(/([a-z])|(\d)|./sg, (m, l, d) => (l ? 1 : d ? 2 : 3) + m), a]
	).sort(([a], [b]) => a.localeCompare(b)).map(a => a[1])

	return (
		<>
		<header className={authHead}>
			<h2>Создать графическую задачу</h2>
		</header>
		<main className={authMain}>
			<form className={formCreate} onSubmit={e=>e.preventDefault()}>
				<label>
					<span>
						Задача:
					</span>
					<select
						value={article}
						onChange={e => setArticle(e.target.value)}
					>
						<option value="">Выберите задачу</option>
						{sortedArticles.map(a => (
							<option key={a.id} value={a.id}>{a.article}</option>
						))}
					</select>
				</label>
				<label>
					<span>
						Описание задачи:
					</span>
					<input
						placeholder="Описание"
						value={description}
						onChange={e=>setDescription(e.target.value)}
					/>
				</label>
				<label>
					<span>
						Дата:
					</span>
					<MyDatePicker
						state={date}
						setState={setDate}
						startDate={date}
						endDate={repeatDay}
						minDate={new Date()}
						selectsStart
					/>
				</label>
				<label>
					<span>
						Повторять каждый:
					</span>
					<input
						value={repeatDay}
						onChange={e=>setRepeatDay(e.target.value)}
						placeholder="Дни повторения"
						type="number"
					/>
					<select value={repeatType} onChange={e=>setRepeatType(e.target.value)}>
						<option value={1}>дней</option>
						<option value={7}>недель</option>
						<option value={28}>месяц</option>
						<option value={364}>год</option>
					</select>
				</label>
				<label>
					<span>
						Выбрать цех:
					</span>
					<select value={departmentId} onChange={e=>setDepartmentId(e.target.value)}>
						<option value={''}>Цех:</option>
						{dep.departments.map(a=>(
							<option key={a.id} value={a.id}>{a.name}</option>
						))}
					</select>
				</label>
				<label>
					<span>
						Станция:
					</span>
					<select
						value={StationId}
						onChange={e => setStationId(e.target.value)}
					>
						<option value="">Выберите станцию:</option>
						{sstation.stations.filter(a=> a.DepartmentId === parseInt(departmentId)).map(a => (
							<option key={a.id} value={a.id}>{a.name}</option>
						))}
					</select>
				</label>
				<label>
					<span>ДУ-46</span>
					<input
						type="checkbox"
						value={du46val}
						checked={du46val}
						className={iCheck}
						onChange={()=>setDU46Val(!du46val)}
					/>
				</label>
				<label>
					<span>Измерение</span>
					<input
						type="checkbox"
						value={checkVoltval}
						checked={checkVoltval}
						className={iCheck}
						onChange={()=>setCheckVoltVal(!checkVoltval)}
					/>
				</label>
				<button
					type="submit"
					className={button}
					style={{marginTop:"auto"}}
					onClick={createGraphTaskHandler}>
					Создать
				</button>
				<style>
					{"input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {-webkit-appearance: none;margin: 0}"}
				</style>
			</form>
		</main>
		</>
	)
}

export default CreateTask
