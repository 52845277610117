import {$authHost} from "."

export const createReason = async (reason, id, setProgress) => {
	const {data} = await $authHost.post('api/reason/'+ id, reason, {
		onUploadProgress: data => {
			setProgress(Math.round((100 * data.loaded) / data.total))
		},
	})
	return data
}

export const createStartDU46 = async (du46, id, setProgress) => {
	const {data} = await $authHost.post('api/reason/start/du46/'+ id, du46, {
		onUploadProgress: data => {
			setProgress(Math.round((100 * data.loaded) / data.total))
		},
	})
	return data
}

export const addFinishDU46 = async (du46, id, setProgress) => {
	const {data} = await $authHost.put('api/reason/finish/du46/'+ id, du46, {
		onUploadProgress: data => {
			setProgress(Math.round((100 * data.loaded) / data.total))
		},
	})
	return data
}

export const getDU46 = async (id) => {
	const {data} = await $authHost.get('api/reason/du46/'+ id)
	return data
}