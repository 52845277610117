import {makeAutoObservable} from "mobx"

export default class SHCHUStore {
	constructor() {
		this._shchus = []
		makeAutoObservable(this)
	}

	pushSHCHU(shchu) {
		this._shchus = [ ...this._shchus, shchu]
	}

	setSHCHUs(shchus) {
		this._shchus = shchus
	}

	get shchus() {
		return this._shchus
	}
}