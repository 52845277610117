import React, { useContext } from 'react'
import { Context } from '../..'
import { Link } from 'react-router-dom'
import { button, margin10 } from '../css/LoginRegister.module.scss'
import { cutting4flex } from '../css/Cabinet.module.scss'
import { authHead, authMain } from '../css/AuthPages.module.scss'
import {
	CREATE_GRAPH_TASK_ROUTE,
	CREATE_UNGRAPH_TASK_ROUTE,
	CREATE_DEPARTMENT_ROUTE,
	CREATE_SHCHU_ROUTE,
	CREATE_STATION_ROUTE,
	CREATE_ARTICLE_ROUTE,
	REGISTER_ROUTE,
	CREATE_VOLT_ROUTE
} from '../../utils/routeNames'
import { KADR_ROLES, OTDELKADROV_ROLE } from '../../utils/roleNames'

const CreateTaskChoose = () => {
	const { user } = useContext(Context)
	return (
		<>
			<header className={authHead}>
				<h2>Выберите тип задачи</h2>
			</header>
			<main className={authMain}>
				<section className={cutting4flex}>
					{!OTDELKADROV_ROLE.includes(user.user.role) && <>
					<Link to={CREATE_GRAPH_TASK_ROUTE}>
						<button className={button}>
							Графиковая задача
						</button>
					</Link>
					<Link to={CREATE_UNGRAPH_TASK_ROUTE}>
						<button className={button +" "+ margin10}>
							Неграф. задача
						</button>
					</Link>
					</>}
					{KADR_ROLES.includes(user.user.role) && <>
					<Link to={CREATE_SHCHU_ROUTE}>
						<button className={button +" "+ margin10}>
							Добавить SHCHU
						</button>
					</Link>
					<Link to={CREATE_DEPARTMENT_ROUTE}>
						<button className={button +" "+ margin10}>
							Добавить цех
						</button>
					</Link>
					<Link to={CREATE_STATION_ROUTE}>
						<button className={button +" "+ margin10}>
							Добавить станцию
						</button>
					</Link>
					<Link to={CREATE_VOLT_ROUTE}>
						<button className={button +" "+ margin10}>
							Добавить измерения
						</button>
					</Link>
					<Link to={CREATE_ARTICLE_ROUTE}>
						<button className={button +" "+ margin10}>
							Добавить задачу
						</button>
					</Link>
					<Link to={REGISTER_ROUTE}>
						<button className={button +" "+ margin10}>
							Добавить пользователя
						</button>
					</Link>
					</>}
				</section>
			</main>
		</>
	)
}

export default CreateTaskChoose