import React from 'react'
import {observer} from "mobx-react-lite"
import { Link, useLocation } from 'react-router-dom'
import {navFooter, active} from '../pages/css/Navbar.module.scss'
import { SHNS_ROLE } from '../utils/roleNames'
import {
	CREATE_TASK_ROUTE,
	HOME_ROUTE,
	MINE_TASKS_SHNS,
	NOT_ASSIGNED_TASK_ROUTE
} from '../utils/routeNames'

const NavBar = observer(({role}) => {
	const just = useLocation().pathname.split("/")[1]
	if(just === "404") return null
	return (
		<footer className={navFooter}>
			<nav>
				<Link className={just === '' ? active: ''} to={HOME_ROUTE}>
					<i className="la la-home la-3x"></i><br/>
					Главная
				</Link>
				<Link className={just === 'create' ? active: ''} to={CREATE_TASK_ROUTE}>
					<i className="la la-plus-circle la-3x"></i><br/>
					Новое
				</Link>
				{role === SHNS_ROLE &&
					<>
						<Link className={just === 'my' ? active: ''} to={MINE_TASKS_SHNS}>
							<i className="la la-user-clock la-3x"></i><br/>
							Задачи
						</Link>
						<Link className={just === 'assign' ? active: ''} to={NOT_ASSIGNED_TASK_ROUTE}>
							<i className="la la-hand-pointer la-3x"></i><br/>
							Сюда!
						</Link>
					</>
				}
				<Link className={just === 'cabinet' ? active: ''} to="/cabinet">
					<i className="la la-user la-3x"></i><br/>
					Профиль
				</Link>
			</nav>
		</footer>
	)
})

export default NavBar
