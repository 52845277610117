import React, { useState, useEffect } from "react"
import { getUserIdByName } from "../http/tasksUpApi"

const SearchWorkersInput = ({setWorker, root=false}) => {

	const [search, setSearch] = useState("")
	const [options, setOptions] = useState([])

	useEffect(()=>{
		const delay = setTimeout(()=>{
			if(search?.length){
				getUserIdByName(search, root).then(data => {
					if(data.length) {
						setOptions(data)
					} else {
						setOptions([])
					}
				})
			} else {
				setOptions([])
			}
		}, 1500)
		return () => clearTimeout(delay)
	} , [search, root])

	return (
		<>
			<label>
				<span>
					Работник:
				</span>
				<input
					placeholder="Введите имя работника"
					value={search}
					onChange={e=>setSearch(e.target.value)}
				/>
			</label>
			<ul>
				{
					options.map(item => (
						<li
							key={item.id}
							onClick={()=>{
								setSearch("")
								setWorker(item)
								setOptions([])
							}}
						>
							{item.username}
						</li>
					))

				}
			</ul>
		</>
	)
}

export default SearchWorkersInput
