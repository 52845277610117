import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './components/AppRouter'
import { observer } from "mobx-react-lite"
import { Context } from '.'
import NavBar from './components/NavBottom'
import NavBarAdmin from './components/NavLeft'
import { check, getName } from "./http/userAPI"
import { getTasksAll } from "./http/taskApi"
import { getTasksUpAll } from "./http/tasksUpApi"
import { getTasksMiddleAll } from "./http/taskMiddleApi"
import { getArticles } from "./http/articleApi"
import { getDepartments } from './http/departmentApi'
import { getSHCHUs } from './http/shchuApi'
import { DOWN_ROLES, KADR_ROLES, MIDDLE_ROLES, UP_ROLES } from './utils/roleNames'
import { getStations } from './http/stationApi'


const App = observer(() => {
	const {user, tasks, tasksMidl, tasksUp, art, sshchu, dep, sstation} = useContext(Context)
	const [loading, setLoading] = useState(true)

	const [windowHeight, setWindowHeight] = useState()

	const rootStyle = document.querySelector('#root').style
	rootStyle.overflow = "auto"

	useEffect(() => {
		const checkAuth = async () => {
			try {
				await check()
				.then(async data => {
					user.setUser(data)
					user.setIsAuth(true)
					await getName()
					.then(data=> user.setName(data))
					if(DOWN_ROLES.includes(user.user.role)) {
						await getTasksAll()
						.then(data => {
							tasks.setActiveTasks(data.activeTasks)
							tasks.setDeletedTasks(data.deletedTasks)
							tasks.setAll()
						})
					} else if(MIDDLE_ROLES.includes(user.user.role)) {
						await getTasksMiddleAll()
						.then(data => {
							tasksMidl.setActiveTasks(data.activeTasks)
							tasksMidl.setNotAssignedTasks(data.notAssignedTasks)
							tasksMidl.setMineTasks(data.mineTasks)
							tasksMidl.setDeletedTasks(data.deletedTasks)
							tasksMidl.setAll()
						})
						await getArticles()
						.then(data => art.setArticles(data))
						await getDepartments()
						.then(data => dep.setDepartments(data))
						await getStations()
						.then(data => sstation.setStations(data))
					} else if(UP_ROLES.includes(user.user.role)) {
						await getTasksUpAll()
						.then(data => {
							tasksUp.setActiveTasks(data.activeTasks)
							tasksUp.setDeletedTasks(data.deletedTasks)
							tasksUp.setAll()
						})
						await getArticles()
						.then(data => art.setArticles(data))
						await getSHCHUs()
						.then(data => sshchu.setSHCHUs(data))
						await getDepartments()
						.then(data => dep.setDepartments(data))
						await getStations()
						.then(data => sstation.setStations(data))
					} else if(KADR_ROLES.includes(user.user.role)) {
						await getArticles()
						.then(data => art.setArticles(data))
						await getSHCHUs()
						.then(data => sshchu.setSHCHUs(data))
						await getDepartments()
						.then(data => dep.setDepartments(data))
						await getStations()
						.then(data => sstation.setStations(data))
					}
				})
				.finally(() => {
					setLoading(false)
				})
			} catch(e) {
				console.log(e.response.data.message)
				setLoading(false)
			}
		}
		checkAuth()
	}, [user, tasks, tasksMidl, tasksUp, art, sshchu, dep, sstation])

	useEffect(() => {
		const updateWindowDimensions = () => {
			const wh = window.innerHeight
			setWindowHeight(wh)
			rootStyle.setProperty('--page-height',!user.isAuth ? wh+"px" : wh-82+"px")
			rootStyle.height = rootStyle.getPropertyValue("--page-height")
		}
		
		updateWindowDimensions()
		
		window.addEventListener("resize", updateWindowDimensions)

		return () => window.removeEventListener("resize", updateWindowDimensions)

	}, [rootStyle, windowHeight, user.isAuth])

	if (loading) {
		return <center><h2>Загрузка...</h2></center>
	}

	return (
		<BrowserRouter>
			<AppRouter role={user.user.role} isAuth={user.isAuth}/>
			{user.isAuth && DOWN_ROLES.includes(user.user.role) && <NavBar/>}
			{user.isAuth && MIDDLE_ROLES.includes(user.user.role) && <NavBarAdmin role={user.user.role}/>}
			{user.isAuth && UP_ROLES.includes(user.user.role) && <NavBarAdmin/>}
			{user.isAuth && user.user.role !== 'admin' && KADR_ROLES.includes(user.user.role) && <NavBarAdmin/>}
		</BrowserRouter>
	)
})

export default App
