import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { authHead, authMain } from "./css/AuthPages.module.scss"
import { taskDetails } from "./css/TasksAll.module.scss"
import { TASKS_ROUTE } from "../utils/routeNames"
import { Context } from ".."

const Home = () => {
	const {activeTasks} = useContext(Context).tasks

	return (
		<>
			<header className={authHead}>
				<h2>Графиковые задачи</h2>
			</header>
			<main className={authMain}>
				{!activeTasks.length ?
					<h3>Пока Пусто!</h3>
				:
				activeTasks.map(task => (
					<Link key={task.id} to={TASKS_ROUTE + '/' + task.id} className={taskDetails}>
						<div>
							<h3>{task.Article.article}</h3>
						</div>
						<div>
							<span>{task.description}</span>
							<span style={{marginLeft: "auto"}}>Работники: {task.Graphs[0].workers.length}</span>
						</div>
					</Link>
				))}
			</main>
		</>
	)
}

export default Home
