import {$authHost} from "."

export const getCheckVolts = async (id) => {
	const {data} = await $authHost.get('api/volt/checks/'+ id)
	return data
}

export const createCheckVolt = async (id, volts) => {
	const {data} = await $authHost.post(
		'api/volt/checks/'+ id,
		{ volts }
	)
	return data
}

export const createVolt = async (name, norma, times, StationId, ArticleId) => {
	const {data} = await $authHost.post(
		'api/volt/',
		{ name, norma, times, StationId, ArticleId }
	)
	return data
}

export const getVolts = async (GraphTaskId) => {
	const {data} = await $authHost.post('api/volt/checks/', { GraphTaskId })
	return data
}