import React from 'react'
import { useHistory } from 'react-router'
import { HOME_ROUTE } from '../utils/routeNames'
import { last, button } from './css/LoginRegister.module.scss'
import { authMain, p404 } from './css/AuthPages.module.scss'


const Page404 = () => {

	const {push} = useHistory()
	
	return (
		<>
			<main className={authMain +" "+ p404}>
				<center style={{display:"flex", flexDirection:"column", height: "100%", flexGrow: 1}}>
					<h3>Упс... Что-то пошло не так</h3>
					<h1 style={{fontSize:"10vh"}}>404</h1>
					<i className="las la-grin-beam-sweat la-8x"></i>
					<h2>Страница не найдена</h2>
					<button className={button +" "+ last} onClick={() => push(HOME_ROUTE)}>
						В главную
					</button>
				</center>
			</main>
		</>
	)
}
export default Page404