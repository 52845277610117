import {$authHost} from "."

export const createSHCHU = async (SHCHUDepId) => {
	const {data} = await $authHost.post('api/shchu', {SHCHUDepId})
	return data
}

export const getSHCHUs = async () => {
	const {data} = await $authHost.get('api/shchu')
	return data
}