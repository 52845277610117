import React, { useState, useEffect, useContext } from "react"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button, mTop05 } from "../css/LoginRegister.module.scss"
import { useHistory } from 'react-router-dom'
import { CREATE_TASK_ROUTE } from "../../utils/routeNames"
import { Context } from "../.."
import { registerUser } from "../../http/userUpAPI"
import moment from "moment"
import MaskInput from 'react-maskinput'
import { ADMIN_ROLE, ALL_ROLES_ARRAY, DOWN_ROLES, KADR_ROLES, MIDDLE_ROLES, SHCHU_ROLE, UP_ROLES } from "../../utils/roleNames"

const CreateUser = () => {
	const {dep, sshchu, sstation} = useContext(Context)
	const [number, setNumber] = useState("")
	const [numberDirty, setNumberDirty] = useState(false)
	const [usernameDirty, setUsernameDirty] = useState(false)
	const [numberErr, setNumberErr] = useState(false)
	const [usernameErr, setUsernameErr] = useState(true)
	const [formValid, setFormValid] = useState(false)
	const [username, setUsername] = useState("")
	const [firstname, setFirstname] = useState("")
	const [middlename, setMiddlename] = useState("")
	const [lastname, setLastname] = useState("")
	const [birthday, setBirthday] = useState("")
	const [SHCHUDepId, setSHCHUDepId] = useState(null)
	const [DepartmentId, setDepartmentId] = useState(null)
	const [StationId, setStationId] = useState(null)
	const [role, setRole] = useState("")
	const {push} = useHistory()
	
	const blurHandler = (e) => {
		switch (e.target.name) {
			case 'number':
				setNumberDirty(true)
				break

			case 'username':
				setUsernameDirty(true)
				break

			default:
				break
		}
	}

	const numberHandler = (e) => {
		setNumber(e.target.value)
		const re = /^\+998[(][3;8;9]{1}[0-9]{1}[)][0-9]{3}[-][0-9]{2}[-][0-9]{2}$/
		if(e.target.value.length) {
			if( !re.test(e.target.value)) {
				setNumberErr('Номер не корректен')
			} else {
				setNumberErr()
			}
		} else {
			setNumberErr()
		}
	}

	const usernameHandler = (e) => {
		setUsername(e.target.value)
		const re = /^[a,A][d,D][m,M][i,I][n,N]|[a,A][l,L][l,L][a,A][h,H]|[s,S][e,E][x,X]|\s/g
		if(e.target.value.length < 3 ||
			e.target.value.length > 16 ||
			re.test(e.target.value)) {
			setUsernameErr('Username неправильно')
		} else {
			setUsernameErr()
		}
	}

	const CreateUserHandler = async () => {
		try {
			const data = await registerUser(
				number,
				moment(birthday).format('DDMMYYYY'),
				username,
				firstname,
				middlename,
				lastname,
				moment(birthday).format('MM DD YYYY'),
				parseInt(StationId),
				parseInt(DepartmentId),
				parseInt(SHCHUDepId),
				role
			)
			alert(data)
			push(CREATE_TASK_ROUTE)
		} catch (e) {
			alert(e.response.data.message)
		}
	}

	useEffect(() => {
		if(numberErr ||
			usernameErr ||
			!firstname ||
			!middlename ||
			!lastname ||
			// !(new Date(birthday).getFullYear()>1960 && new Date(birthday).getFullYear()<new Date()) ||
			!ALL_ROLES_ARRAY.includes(role)) {
			setFormValid(false)
		} else if(DOWN_ROLES.includes(role) && (!SHCHUDepId || !DepartmentId || !StationId)){
			setFormValid(false)
		} else if(MIDDLE_ROLES.includes(role) && (!SHCHUDepId || !DepartmentId)){
			setFormValid(false)
		} else if([SHCHU_ROLE].includes(role) && !SHCHUDepId) {
			setFormValid(false)
		} else {
			setFormValid(true)
		}
	}, [numberErr, usernameErr, firstname, middlename, lastname, birthday, role, DepartmentId, SHCHUDepId, StationId])

	useEffect(() => {
		if([...UP_ROLES, ...KADR_ROLES].includes(role)) {
			setDepartmentId(null)
			setStationId(null)
		}
		if(MIDDLE_ROLES.includes(role)) {
			setStationId(null)
		}
	}, [role])

	return (
		<>
			<header className={authHead}>
				<h2>Создать пользователя</h2>
			</header>
			<main className={authMain}>
				<form className={formCreate} onSubmit={e=>e.preventDefault()}>
					<label>
						<span>
							Номер телефона
						</span>
						<MaskInput
							autoComplete="number"
							value={number}
							name="number"
							onChange={numberHandler}
							onBlur={blurHandler}
							mask="+998(00)000-00-00"
							placeholder="+998(90)123-45-67 или Оставьте поле пустым"
							title="Поле не обязательное"
						/>
						{(numberDirty && numberErr) && <span style={{color: "red"}}>{numberErr}</span>}
					</label>
					<label>
						<span>
							Фамилия
						</span>
						<input
							placeholder="Фамилия"
							value={firstname}
							onChange={e=>setFirstname(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Имя
						</span>
						<input
							placeholder="Имя"
							value={middlename}
							onChange={e=>setMiddlename(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Отчество
						</span>
						<input
							placeholder="Отчество"
							value={lastname}
							onChange={e=>setLastname(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Username
						</span>
						<input
							placeholder="Username"
							value={username}
							name="username"
							onChange={usernameHandler}
							onBlur={blurHandler}
						/>
						{(usernameDirty && usernameErr) && <span style={{color: "red"}}>{usernameErr}</span>}
					</label>
					<label>
						<span>
							Дата Рождение
						</span>
						<input
							type="date"
							value={birthday}
							onChange={e => setBirthday(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Роль:
						</span>
						<select
							value={role}
							onChange={e => setRole(e.target.value)}
						>
							<option value="">Выберите должность:</option>
							{
								ALL_ROLES_ARRAY.filter(rol => rol !== ADMIN_ROLE).map(rol => {
									return <option key={rol} value={rol}>{rol}</option>
								})
							}
						</select>
					</label>
					{[...KADR_ROLES, ...UP_ROLES.filter(role=> role !== SHCHU_ROLE), ''].includes(role) ?
						null
					:
						<label>
							<span>
								SHCHU:
							</span>
							<select
								value={SHCHUDepId}
								onChange={e => setSHCHUDepId(e.target.value)}
							>
								<option value="">Выберите SHCHU</option>
								{sshchu.shchus.map(a => (
									<option key={a.id} value={a.id}>{a.name}</option>
								))}
							</select>
						</label>
					}
					{([...KADR_ROLES, ...UP_ROLES, ''].includes(role)) ?
						null
					:
						<label>
							<span>
								Цех:
							</span>
							<select
								value={DepartmentId}
								onChange={e => setDepartmentId(e.target.value)}
							>
								<option value="">Выберите цех</option>
								{dep.departments.filter(d=> d.SHCHUDepId == SHCHUDepId).map(a => (// eslint-disable-line
									<option key={a.id} value={a.id}>{a.name}</option>
								))}
							</select>
						</label>
					}
					{[...KADR_ROLES, ...UP_ROLES, ...MIDDLE_ROLES, ''].includes(role) ?
						null
						:
							<label>
								<span>
									Станция:
								</span>
								<select
									value={StationId}
									onChange={e => setStationId(e.target.value)}
								>
									<option value="">Выберите станцию</option>
									{sstation.stations.filter(d=> d.DepartmentId === parseInt(DepartmentId)).map(a => (
										<option key={a.id} value={a.id}>{a.name}</option>
									))}
								</select>
							</label>
					}
					<button
						type="submit"
						className={button}
						style={{marginTop:"auto"}}
						disabled={!formValid}
						onClick={CreateUserHandler}
					>
						Создать пользователя
					</button>
					<button
						className={button +" "+ mTop05}
						onClick={()=>push(CREATE_TASK_ROUTE)}>
							Отмена
					</button>
				</form>
			</main>
		</>
	)
}

export default CreateUser
