import {$authHost, $host} from "."
import jwt_decode from "jwt-decode"

export const login = async (username, password) => {
	const {data} = await $host.post('api/user/login', {username, password})
	localStorage.setItem('token', data.token)
	return jwt_decode(data.token)
}
export const check = async () => {
	const {data} = await $authHost.get('api/user/auth')
	localStorage.setItem('token', data.token)
	return jwt_decode(data.token)
}

export const getName = async () => {
	const {data} = await $authHost.get('api/user/name')
	return data
}

export const updatePassword = async (oldpassword, password) => {
	const {data} = await $authHost.post('api/user/update/pass', {oldpassword, password})
	localStorage.setItem('token', data.token)
	return jwt_decode(data.token)
}

export const updateNumber = async (number) => {
	const {data} = await $authHost.post('api/user/update/number', {number})
	localStorage.setItem('token', data.token)
	return jwt_decode(data.token)
}