import {makeAutoObservable} from "mobx"

export default class TasksMiddleStore {
	constructor() {
		this._activeTasks = []
		this._notAssignedTasks = []
		this._mineTasks = []
		this._deletedTasks = []
		this._all = []
		makeAutoObservable(this)
	}


	_checkPush(task, array) {
		if(task?.constructor.name === 'Array') {
			array.push(...task)
		} else if(task?.constructor.name === 'Object') {
			array.push(task)
		}
	}

	setActiveTasks(activeTasks) {
		this._checkPush(activeTasks, this._activeTasks)
	}

	setNotAssignedTasks(notAssignedTasks) {
		this._checkPush(notAssignedTasks, this._notAssignedTasks)
	}

	setMineTasks(mineTasks) {
		this._checkPush(mineTasks, this._mineTasks)
	}

	addActiveReason(id, res) {
		this._mineTasks.find(t=> t.id === parseInt(id)).Graphs[0].GraphReason = res
		this.setActiveTasks(this._mineTasks.find(t=>t.id=== parseInt(id)))
		this._mineTasks.filter(t=> t.id !== parseInt(id))
	}

	setDeletedTasks(deletedTasks) {
		this._checkPush(deletedTasks, this._deletedTasks)
	}

	setAll() {
		this._checkPush(this._activeTasks, this._all)
		this._checkPush(this._deletedTasks, this._all)
	}

	clearAllTasks() {
		this._activeTasks = []
		this._notAssignedTasks = []
		this._mineTasks = []
		this._deletedTasks = []
	}

	get activeTasks() {
		return this._activeTasks
	}
	get notAssignedTasks() {
		return this._notAssignedTasks
	}
	get mineTasks() {
		return this._mineTasks
	}
	get deletedTasks() {
		return this._deletedTasks
	}
	get all() {
		return this._all
	}

	removeAssignedTask(tasks) {
		this._notAssignedTasks = tasks
	}
}