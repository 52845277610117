import {$authHost} from "."

export const createGraphTask = async (article, description, location, repeatDay, date, addons, DepartmentId, StationId) => {
	const {data} = await $authHost.post('api/t/root', {
		article,
		description,
		location,
		repeatDay,
		date,
		addons,
		DepartmentId,
		StationId
	})
	return data
}

export const createUnGraphTask = async (article, description, location, deadline, workers) => {
	const {data} = await $authHost.post('api/t/root', {
		article,
		description,
		location,
		deadline,
		workers
	})
	return data
}

export const getUserIdByName = async (username, root = false) => {
	const {data} = await $authHost.post('api/t/root/user?root='+root, {username})
	return data
}

export const getTasksUpAll = async () => {
	const {data} = await $authHost.get('api/t/root/all')
	return data
}

export const updateTask = async (id, title, description, location, deadline, workers) => {
	const {data} = await $authHost.put('api/t/root/' + id, {
		title,
		description,
		location,
		deadline,
		workers
	})
	return data
}

export const deleteTask = async (id) => {
	const {data} = await $authHost.delete('api/t/root/' + id)
	return data
}