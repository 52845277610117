import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { authHead, authMain } from "./css/AuthPages.module.scss"
import { Context } from ".."
import { useParams } from "react-router-dom"
import { button, last } from './css/LoginRegister.module.scss'
import {
	HOME_ROUTE,
	REASON_ROUTE,
	REASON_VIEW_ROUTE
} from "../utils/routeNames"

const Task = () => {
	const {tasks} = useContext(Context)
	const {id} = useParams()
	const task = tasks.all.find(task => task.id === parseInt(id))
	const {push} = useHistory()

	const redirectReasonHandler = () => {
		push(REASON_ROUTE + '/' + task.id)
	}

	const redirectViewReasonHandler = () => {
		push(REASON_VIEW_ROUTE + '/' + task.id)
	}

	return (
		tasks.all.length > 0 ?
		<>
			<header className={authHead}>
				<h2>Задача № <a
					target="_blank"
					rel="noopener noreferrer"
					href={process.env.REACT_APP_DOCS_URL+ task.Article.document}
					>
						{task.Article.article}
					</a>
				</h2>
			</header>
			<main className={authMain}>
				<div>Описание: &nbsp;&nbsp;<b>{task.Article.description}</b></div>
				<div>Заметка: <b>{task.description}</b></div>
				<div>Работники: <ol style={{ margin: 0, padding: "0 1rem", color: 'white' }}>
				{task.Graphs[0].GraphWorkers.length ? 
				<ol style={{ margin: 0, padding: "0 1rem", color: 'white' }}>
					{task.Graphs[0].GraphWorkers.map(
						worker => {
							return <li key={worker.UserId}>{worker.User.firstname + " " + worker.User.middlename}</li>
						}
					)}
				</ol>
				:
				<>Неизвестно</>}
				</ol></div>
				<div>Статус: <b>{task.status}</b></div>
				<nav className={last} style={{display: "flex"}}>
					{!task.Graphs[0].GraphReason ?
						<button
							className={button}
							style={{marginRight: "10px"}}
							onClick={redirectReasonHandler}>
							Приступить
						</button>
					:
						<button
							className={button}
							style={{marginRight: "10px"}}
							onClick={redirectViewReasonHandler}>
							Перейти
						</button>
					}
					<button
						className={button}
						onClick={() => push(HOME_ROUTE)}>
						Назад
					</button>
				</nav>
			</main>
		</>
		: <header className={authHead}>
			<h2>
				Задача не найдена
			</h2>
		</header>
	)
}

export default Task
