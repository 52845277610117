import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import SearchWorkerInput from "../../components/SearchWorkerInput"
import { updateNumberUp } from "../../http/userUpAPI"
import { CABINET_ROUTE } from "../../utils/routeNames"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button, mTop05 } from "../css/LoginRegister.module.scss"
import MaskInput from 'react-maskinput'

const ChangeNumberUp = () => {
	const [worker, setWorker] = useState({})
	const [number, setNumber] = useState("")
	const [numberDirty, setNumberDirty] = useState(false)
	const [numberErr, setNumberErr] = useState(true)
	const [formValid, setFormValid] = useState(false)
	const {push} = useHistory()

	const blurHandler = () => {
		setNumberDirty(true)
	}

	const numberHandler = (e) => {
		setNumber(e.target.value)
		const re = /^\+998[(][3;8;9]{1}[0-9]{1}[)][0-9]{3}[-][0-9]{2}[-][0-9]{2}$/
		if( !re.test(e.target.value)) {
			setNumberErr('Номер не корректен')
		} else {
			setNumberErr()
		}
	}
	
	useEffect(() => {
		if(numberErr || !worker?.id){
			setFormValid(false)
		} else {
			setFormValid(true)
		}
	}, [numberErr, worker])

	const ChangeNumberUpHandler = async () => {
		try {
			await updateNumberUp(worker.id, number)
			.then(data => alert(data))
			push(CABINET_ROUTE)
		} catch (e) {
			alert(e.response.data.message)
		}
	}


	return (
		<>
		<header className={authHead}>
			<h2>Изменить номер пользователя</h2>
		</header>
		<main className={authMain}>
			<form className={formCreate} onSubmit={e=>e.preventDefault()}>
				
				<SearchWorkerInput
					setWorker={setWorker}
					root={true}
				/>
				<b>{worker?.username}</b>
				<label>
					<span>
						Номер телефона:
					</span>
					<MaskInput
						autoComplete="number" type="tel"
						name="number"
						value={number}
						onChange={e => numberHandler(e)}
						onBlur={e => blurHandler(e)}
						mask="+998(00)000-00-00"
						placeholder="Поле не обязательное"
						title="Поле не обязательное"
						
					/>
				</label>
				{(numberDirty && numberErr) && <abs>{numberErr}</abs>}
				<button
					type="submit"
					className={button}
					style={{marginTop:"auto"}}
					disabled={!formValid}
					onClick={ChangeNumberUpHandler}
				>
					Изменить номер
				</button>
				<button
					className={button +" "+ mTop05}
					onClick={()=>push(CABINET_ROUTE)}>
						Отмена
				</button>
			</form>
		</main>
		</>
	)
}

export default ChangeNumberUp
