import {$authHost} from "."

export const getTasks = async () => {
	const {data} = await $authHost.get('api/t/user')
	return data
}

export const getTask = async (id) => {
	const {data} = await $authHost.get('api/t/user/' + id)
	return data
}

export const getTasksAll = async () => {
	const {data} = await $authHost.get('api/t/user/all')
	return data
}
