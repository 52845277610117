import {$authHost} from "."

export const createFeedback = async (feedback) => {
	const {data} = await $authHost.post('api/feedback', feedback)
	return data
}

export const getFeedbacks = async () => {
	const {data} = await $authHost.get('api/feedback')
	return data
}

export const fixFeedback = async (id, isFixed) => {
	const {data} = await $authHost.put('api/feedback'+ id, {isFixed})
	return data
}