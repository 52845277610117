import {makeAutoObservable} from "mobx"

export default class DepartmentStore {
	constructor() {
		this._departments = []
		makeAutoObservable(this)
	}

	pushDepartment(department) {
		this._departments = [ ...this._departments, department]
	}

	setDepartments(departments) {
		this._departments = departments
	}

	get departments() {
		return this._departments
	}
}