import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import SearchWorkerInput from "../../components/SearchWorkerInput"
import { updateRoleUp } from "../../http/userUpAPI"
import { ADMIN_ROLE, ALL_ROLES_ARRAY } from "../../utils/roleNames"
import { CABINET_ROUTE } from "../../utils/routeNames"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button, mTop05 } from "../css/LoginRegister.module.scss"

const ChangeRoleUp = () => {
	const [worker, setWorker] = useState({})
	const [role, setRole] = useState("")
	const [formValid, setFormValid] = useState(false)
	const {push} = useHistory()

	useEffect(() => {
		if(!role || !worker?.id){
			setFormValid(false)
		} else {
			setFormValid(true)
		}
	}, [role, worker])

	const ChangeRoleUpHandler = async () => {
		try {
			await updateRoleUp(worker.id, role)
			.then(data => alert(data))
			push(CABINET_ROUTE)
		} catch (e) {
			alert(e.response.data.message)
		}
	}

	return (
		<>
		<header className={authHead}>
			<h2>Изменить роль пользователя</h2>
		</header>
		<main className={authMain}>
			<form className={formCreate} onSubmit={e=>e.preventDefault()}>
				
				<SearchWorkerInput
					setWorker={setWorker}
					root={true}
				/>
				<b>{worker?.username}</b>
				<label>
					<span>
						Должность:
					</span>
					<select
						value={role}
						onChange={e => setRole(e.target.value)}
					>
						<option value="">Выберите должность:</option>
						{
							ALL_ROLES_ARRAY.filter(rol => rol !== ADMIN_ROLE).map(rol => {
								return <option key={rol} value={rol}>{rol}</option>
							})
						}
					</select>
				</label>
				<button
					type="submit"
					className={button}
					style={{marginTop:"auto"}}
					disabled={!formValid}
					onClick={ChangeRoleUpHandler}
				>
					Изменить должность
				</button>
				<button
					className={button +" "+ mTop05}
					onClick={()=>push(CABINET_ROUTE)}>
						Отмена
				</button>
			</form>
		</main>
		</>
	)
}

export default ChangeRoleUp
