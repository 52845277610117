import React, { useState, useContext } from "react"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import { formCreate } from "../css/CreateTask.module.scss"
import { button, mTop05 } from "../css/LoginRegister.module.scss"
import { useHistory } from 'react-router-dom'
import { CREATE_TASK_ROUTE } from "../../utils/routeNames"
import { createArticle } from "../../http/articleApi"
import { Context } from "../.."

const AddArticle = () => {

	const {art} = useContext(Context)
	const [article, setArticle] = useState("")
	const [description, setDescription] = useState("")
	const [document, setDocument] = useState("")
	const {push} = useHistory()

	const CreateArticleHandler = async () => {
		try {
			const articleForm = new FormData()
			articleForm.append("article", article)
			articleForm.append("description", description)
			articleForm.append("document", document)
			const [data, id] = await createArticle(articleForm)
			art.pushArticle({id, article})
			alert(data)
			push(CREATE_TASK_ROUTE)
		} catch (e) {
			alert(e.response.data.message)
		}
	}

	return (
		<>
			<header className={authHead}>
				<h2>Создать задачу</h2>
			</header>
			<main className={authMain}>
				<form className={formCreate} onSubmit={e=>e.preventDefault()}>
					<label>
						<span>
							Название задачи:
						</span>
						<input
							placeholder="Название"
							value={article}
							onChange={e=>setArticle(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Описание задачи:
						</span>
						<input
							placeholder="Описание"
							value={description}
							onChange={e=>setDescription(e.target.value)}
						/>
					</label>
					<label>
						<span>
							Документация задачи:
						</span>
						<input
							placeholder="Документ"
							accept="application/pdf"
							type="file"
							onChange={e=>setDocument(e.target.files[0])}
						/>
					</label>
					<button
						type="submit"
						className={button}
						style={{marginTop:"auto"}}
						onClick={CreateArticleHandler}
					>
						Создать задачу
					</button>
					<button
						className={button +" "+ mTop05}
						onClick={()=>push(CREATE_TASK_ROUTE)}>
							Отмена
					</button>
				</form>
			</main>
		</>
	)
}

export default AddArticle
