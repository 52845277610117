import React, { useContext, useState } from "react"
import { authHead, authMain } from "../css/AuthPages.module.scss"
import {
	photosFigure,
	depTasksStyledGroup,
	depTasksStyledArticle,
	depsBtns,
	depsBtn,
	green,
	yellow,
	red,
	white,
	chvoltOne
} from "../css/HomeUp.module.scss"
import { Context } from "../.."
import {
	SHNS_ROLE
} from '../../utils/roleNames'

const HomeUp = () => {
	const {user, tasksUp, tasksMidl, art, dep, sstation} = useContext(Context)
	const Task = user.user.role === SHNS_ROLE ? tasksMidl : tasksUp
	const [depOne, setDepOne] = useState('')
	const [stationOne, setStationOne] = useState('')

	const onDepRadioChanged = (e) => {
		setDepOne(e.target.value)
		setStationOne('')
		changeStyles(e)
	}

	const onStationRadioChanged = (e) => {
		setStationOne(e.target.value)
		changeStyles(e)
	}

	const taskSts = (boolean) => {
		const tasker = Task.activeTasks.map(
			task => {
				return [task.Graphs[0].GraphReason?.isCompleted, boolean ? task.DepartmentId : task.StationId]
			}
		)
		const reduced = tasker.reduce((prev, [bool, val]) => {
			prev[val] = prev[val] ?? []
			prev[val].push(bool)
			return prev
		}, [])

		for(let arr = 0; arr < reduced.length; arr++) {
			if(reduced[arr]) {
				if(reduced[arr].every(array => array === true)) {
					reduced[arr] = [green]
				} else if(reduced[arr].every(array => array === undefined)){
					reduced[arr] = [yellow]
				} else if(reduced[arr].some(array => array === false)){
					reduced[arr] = [red]
				} else {
					reduced[arr] = [white]
				}
			}
		}
		return reduced
	}

	const checkDep = (dep, bool) => {
		if(!taskSts(bool)[dep]) {
			return [white]
		} else {
			return taskSts(bool)[dep][0]
		}
	}

	const stationTasks = function() {
		return Task.activeTasks.filter(
			task => task.StationId === parseInt(stationOne)
		)
	}()

	const bgColorChvolt = function(chvolt) {
		if(chvolt.status === 0) {return green}
		else if(chvolt.status === 1) {return yellow}
		else if(chvolt.status === 2) {return red}
		else return
	}

	const changeStyles = e => {
		const plabel = e.target.parentElement
		const plables = plabel.parentElement.querySelectorAll('label')
		plables.forEach(label => {
			label.style.border = 0
			label.style.height = '50px'
		})
		plabel.style.border = '5px solid #fff'
		plabel.style.height = '40px'
	}

	return (
		<>
			<header className={authHead}>
				<h2>Задачи на {new Date().toLocaleDateString()}</h2>
			</header>
			<main className={authMain}>
				<div className={depsBtns}>
					{dep.departments.map(deps =>
						<label
							className={depsBtn+" "+checkDep(deps.id, true)}
							key={deps.id}
							id={deps.id}
						>
							<b>{deps.name}</b>
							<input type="radio" name="radioForDeps" value={deps.id} hidden onChange={onDepRadioChanged}/>
						</label>
					)}
				</div>
				{depOne && <h3>Станции</h3>}
				<div className={depsBtns}>
					{sstation.stations.filter(station => station.DepartmentId === parseInt(depOne))
					.map(station =>
						<label
							className={depsBtn+" "+checkDep(station.id, false)}
							key={station.id}
							id={station.id}
						>
							<b style={{fontSize: '17px'}}>{station.name}</b>
							<input type="radio" name="radioForStations" value={station.id} hidden onChange={onStationRadioChanged}/>
						</label>
					)}
				</div>

				{stationOne &&
					<div className={depTasksStyledGroup}>
						{stationTasks.map(task =>
							<article className={depTasksStyledArticle} key={task.id}>
								<div><b>Название задачи:</b> {
									art.articles.find(article => article.id === task.ArticleId).article
								}</div>
								<div><b>Описание задачи:</b> {
									art.articles.find(article => article.id === task.ArticleId).description
								}</div>
								<div>
									<b>Заметка:</b> {task.description}
								</div>
								<div>
									<b>Работники: </b>
									{task.Graphs[0].GraphWorkers.length ? 
									<ol style={{ margin: 0, padding: "0 1rem", color: 'white' }}>
										{task.Graphs[0].GraphWorkers.map(
											worker => {
												return <li key={worker.UserId}>{worker.User.firstname + " " + worker.User.middlename}</li>
											}
										)}
									</ol>
									:
									<>Неизвестно</>}
								</div>
								<div>
									<b>Статус:</b> {
										task.Graphs[0].GraphReason ? (
											task.Graphs[0].GraphReason.isCompleted ?
											<>
												Выполнено
												<br/>
												<figure className={photosFigure}>{
													task.Graphs[0].GraphReason.photos.map(photo =>
														<img
															key={photo}
															alt={
																`Фото задачи № ${
																	art.articles.find(article => article.id === task.ArticleId).article
																}`
															}
															src={'/img/'+photo}
															style={{width: "100%"}}
														/>
													)
												}</figure>
											</>
											:
											<>
												Не выполнено
												<br/>
												<div><b>Причина:</b> {task.Graphs[0].GraphReason.reason}</div>
											</>
										) : 'Не указано'
									}
								</div>
								<div>
									{
										task.Graphs[0].DU46 &&
										<>
											<h3>ДУ46</h3>
											<img
												src={"/img/" + task.Graphs[0].DU46.start}
												width={400}
												height={400}
												alt={"Фото задачи " + task.id}
											/>
											{task.Graphs[0].DU46.finish &&
												<img
													src={"/img/" + task.Graphs[0].DU46.finish}
													width={400}
													height={400}
													alt={"Фото задачи " + task.id}
												/>
											}
										</>
									}
								</div>
								<div>
									{
										task.Graphs[0]?.CheckVolts?.length ?
										<>
											<h3>Напрежении</h3>
											{task.Graphs[0].CheckVolts.map(chvolt => (
												<div className={chvoltOne +" "+(bgColorChvolt(chvolt))} key={chvolt.id}>
													<p>
														{chvolt.Volt?.name}
													</p>
													<p>
														{chvolt.volt}
													</p>
													<p>
														{chvolt.Volt?.norma}
													</p>
												</div>
											))}
										</>
										: null
									}
								</div>
							</article>
						)}
					</div>
				}
			</main>
		</>
	)
}

export default HomeUp
