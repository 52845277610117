import {$authHost} from "."

export const createGraphTask = async (article, description, location, repeatDay, date, addons, workers, StationId) => {
	const {data} = await $authHost.post('api/t/midl', {
		article,
		description,
		location,
		repeatDay,
		date,
		workers,
		StationId,
		addons
	})
	return data
}

export const createUnGraphTask = async (article, description, location, deadline, workers) => {
	const {data} = await $authHost.post('api/t/midl', {
		article,
		description,
		location,
		deadline,
		workers
	})
	return data
}

export const generateCreatedTask = async(id, workers, StationId) => {
	const {data} = await $authHost.post('api/t/midl/setWorkers', {
		id,
		workers,
		StationId
	})
	return data
}

export const getUserIdByName = async (username, root = false) => {
	const {data} = await $authHost.post('api/t/midl/user?root='+root, {username})
	return data
}

export const getTasksUp = async () => {
	const {data} = await $authHost.get('api/t/midl')
	return data
}

export const getTasksMiddleAll = async () => {
	const {data} = await $authHost.get('api/t/midl/all')
	return data
}

export const getTaskUp = async (id) => {
	const {data} = await $authHost.get('api/t/midl/' + id)
	return data
}

export const updateTask = async (id, title, description, location, deadline, workers) => {
	const {data} = await $authHost.put('api/t/midl/' + id, {
		title,
		description,
		location,
		deadline,
		workers
	})
	return data
}

export const deleteTask = async (id) => {
	const {data} = await $authHost.delete('api/t/midl/' + id)
	return data
}