import {$authHost} from "."

export const createStation = async (StationId, DepartmentId) => {
	const {data} = await $authHost.post('api/station', { StationId, DepartmentId })
	return data
}

export const getStations = async () => {
	const {data} = await $authHost.get('api/station')
	return data
}