import { observer } from 'mobx-react-lite'
import React, {useContext, useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import { HOME_ROUTE } from '../utils/routeNames'
import { Context } from '..'
import { login, getName } from "../http/userAPI"
import { loginForm, last, button } from './css/LoginRegister.module.scss'
import { getTasksAll } from '../http/taskApi'
import { getTasksMiddleAll } from '../http/taskMiddleApi'
import { getTasksUpAll } from '../http/tasksUpApi'
import { getArticles } from "../http/articleApi"
import { getDepartments } from '../http/departmentApi'
import { UP_ROLES, MIDDLE_ROLES, DOWN_ROLES, KADR_ROLES } from '../utils/roleNames'
import { getSHCHUs } from '../http/shchuApi'
import { getStations } from '../http/stationApi'

const Login = observer(() => {
	const {user, tasks, tasksMidl, tasksUp, art, sshchu, dep, sstation} = useContext(Context)
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [usernameDirty, setUsernameDirty] = useState(false)
	const [passwordDirty, setPasswordDirty] = useState(false)
	const [usernameErr, setUsernameErr] = useState(true)
	const [passwordErr, setPasswordErr] = useState(true)
	const [formValid, setFormValid] = useState(false)

	const next = useHistory().location.search.split('?next=')[1]
	const {push} = useHistory()

	const blurHandler = (e) => {
		switch (e.target.name) {
			case 'username':
				setUsernameDirty(true)
				break
			case 'password':
				setPasswordDirty(true)
				break
			default:
				break
		}
	}

	const usernameHandler = (e) => {
		setUsername(e.target.value)
		const re = /^[a,A][d,D][m,M][i,I][n,N]|[a,A][l,L][l,L][a,A][h,H]|[s,S][e,E][x,X]|\s/g
		if( re.test(e.target.value) || e.target.value.length < 3 || e.target.value.length > 16 ) {
			setUsernameErr('Username не корректен')
		} else {
			setUsernameErr()
		}
	}

	const passwordHandler = (e) => {
		setPassword(e.target.value)
		if(e.target.value.length < 8 || e.target.value.length > 24){
			setPasswordErr('Пароль неверный')
		} else {
			setPasswordErr()
		}
	}

	const loginCheck = async () => {
		try {
			await login(username, password).then(async data=> {
				user.setUser(data)
				user.setIsAuth(true)
				await getName()
				.then(data=> user.setName(data))

				if(DOWN_ROLES.includes(user.user.role)) {
					await getTasksAll()
					.then(data => {
						tasks.setActiveTasks(data.activeTasks)
						tasks.setDeletedTasks(data.deletedTasks)
						tasks.setAll()
					})
				} else if(MIDDLE_ROLES.includes(user.user.role)) {
					await getTasksMiddleAll()
					.then(data => {
						tasksMidl.setActiveTasks(data.activeTasks)
						tasksMidl.setNotAssignedTasks(data.notAssignedTasks)
						tasksMidl.setMineTasks(data.mineTasks)
						tasksMidl.setDeletedTasks(data.deletedTasks)
						tasksMidl.setAll()
					})
					await getArticles()
					.then(data => art.setArticles(data))
					await getDepartments()
					.then(data => dep.setDepartments(data))
					await getStations()
					.then(data => sstation.setStations(data))
				} else if(UP_ROLES.includes(user.user.role)) {
					await getTasksUpAll()
					.then(data => {
						tasksUp.setActiveTasks(data.activeTasks)
						tasksUp.setDeletedTasks(data.deletedTasks)
						tasksUp.setAll()
					})
					await getArticles()
					.then(data => art.setArticles(data))
					await getSHCHUs()
					.then(data => sshchu.setSHCHUs(data))
					await getDepartments()
					.then(data => dep.setDepartments(data))
					await getStations()
					.then(data => sstation.setStations(data))
				} else if(KADR_ROLES.includes(user.user.role)) {
					await getArticles()
					.then(data => art.setArticles(data))
					await getSHCHUs()
					.then(data => sshchu.setSHCHUs(data))
					await getDepartments()
					.then(data => dep.setDepartments(data))
					await getStations()
					.then(data => sstation.setStations(data))
				}
				push(next || HOME_ROUTE)
			})
		} catch (e) {
			alert(e.response.data.message)
		}
	}

	useEffect(() => {
		if(usernameErr || passwordErr){
			setFormValid(false)
		} else {
			setFormValid(true)
		}
	}, [usernameErr, passwordErr])

	return (
		<form className={loginForm} onSubmit={e => e.preventDefault()}>
			<header>
				<h2>Войти</h2>
			</header>
			<main>
				<label>
					<i className="la la-user la-2x" />
					<div>
						<span>Username {(usernameDirty && usernameErr) && <abs>{usernameErr}</abs>}</span>
						<input required
						autoFocus={true}
						autoComplete="username"
						name="username"
						placeholder="Username"
						value={username}
						onChange={e => usernameHandler(e)}
						onBlur={e => blurHandler(e)}
						/>
					</div>
				</label>
				<label>
					<i className="la la-lock la-2x" />
					<div>
						<span>Пароль {(passwordDirty && passwordErr) && <abs>{passwordErr}</abs>}</span>
						<input type="password" required={true}
						placeholder="Укажите свой пароль"
						name="password"
						value={password}
						onChange={e => passwordHandler(e)}
						onBlur={e => blurHandler(e)}
						autoComplete="current-password"
						/>
					</div>
				</label>
				<label className={last}>
					<button className={button}
					disabled={!formValid}
					onClick={loginCheck}
					>Войти</button>
				</label>
				<label>
					<div>
						<i className='la la-telegram la-2x'/>
						<a href='https://t.me/+998330234507'>
							Получить поддержку по телеграм
						</a>
					</div>
					<div>
						<i className='la la-phone la-2x'/>
						<a href='tel:+998995001040'>
							Получить поддержку по телефон
						</a>
					</div>
				</label>
			</main>
		</form>
	)
})

export default Login
