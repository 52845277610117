import React, {useState, useEffect, useContext} from 'react'
import { Context } from '..'
import { useHistory } from 'react-router-dom'
import { CABINET_ROUTE } from '../utils/routeNames'
import { updateNumber } from '../http/userAPI'
import { authHead, authMain } from "./css/AuthPages.module.scss"
import { formCreate } from "./css/CreateTask.module.scss"
import { button } from "./css/LoginRegister.module.scss"
import MaskInput from 'react-maskinput'

const ChangeNumber = () => {
	const {user} = useContext(Context)
	const [number, setNumber] = useState("")
	const [numberDirty, setNumberDirty] = useState(false)
	const [numberErr, setNumberErr] = useState(true)
	const [formValid, setFormValid] = useState(false)
	const {push} = useHistory()

	useEffect(() => {
		if(user.user.number.length>0) {
			push(CABINET_ROUTE)
			return alert('Пацан ты чего хочешь от меня?')
		}
	}, [push, user])

	const blurHandler = () => {
		setNumberDirty(true)
	}

	const numberHandler = (e) => {
		setNumber(e.target.value)
		const re = /^\+998[(][3;8;9]{1}[0-9]{1}[)][0-9]{3}[-][0-9]{2}[-][0-9]{2}$/
		if( !re.test(e.target.value)) {
			setNumberErr('Номер не корректен')
		} else {
			setNumberErr()
		}
	}
	
	useEffect(() => {
		if(numberErr){
			setFormValid(false)
		} else {
			setFormValid(true)
		}
	}, [numberErr])

	const ChangeNumberUpHandler = async () => {
		try {
			await updateNumber(number)				
			user.setUser({...user.user, number})
			push(CABINET_ROUTE)
		} catch (e) {
			alert(e.response.data.message)
		}
	}

	return (
		<>
			<header className={authHead}>
				<h2>Изменение номера</h2>
			</header>
			<main className={authMain}>
				<form className={formCreate} onSubmit={e => e.preventDefault()}>
				<label>
					<span>
						Номер телефона:
					</span>
					<MaskInput
						autoComplete="number" type="tel"
						name="number"
						value={number}
						onChange={e => numberHandler(e)}
						onBlur={e => blurHandler(e)}
						mask="+998(00)000-00-00"
						placeholder="Поле не обязательное"
						title="Поле не обязательное"
					/>
					</label>
					{(numberDirty && numberErr) && <abs>{numberErr}</abs>}
					<button
						type="submit"
						className={button}
						style={{marginTop:"auto"}}
						disabled={!formValid}
						onClick={ChangeNumberUpHandler}
					>
						Изменить номер
					</button>

				</form>
			</main>
		</>
	)
}

export default ChangeNumber