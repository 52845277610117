import {$authHost} from "."

export const createArticle = async (article) => {
	const {data} = await $authHost.post('api/article', article)
	return data
}

export const getArticles = async () => {
	const {data} = await $authHost.get('api/article')
	return data
}