import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { authHead, authMain } from "./css/AuthPages.module.scss"
import { taskDetails } from "./css/TasksAll.module.scss"
import { TASKS_ROUTE } from "../utils/routeNames"
import { Context } from ".."

const History = () => {
	const {deletedTasks} = useContext(Context).tasks

	return (
		<>
			<header className={authHead}>
				<h2>История</h2>
			</header>
			<main className={authMain}>
				{deletedTasks.map(task => (
					<Link key={task.id} to={TASKS_ROUTE + '/' + task.id} className={taskDetails}>
						<div>
							<h3>{task.article}</h3>
						</div>
						<div>
							<span>{task.description}</span>
							<span style={{marginLeft: "auto"}}>Работники: {task.workers.length}</span>
						</div>
					</Link>
				))}
			</main>
		</>
	)
}

export default History
