import {makeAutoObservable} from "mobx"

export default class ArticleStore {
	constructor() {
		this._articles = []
		makeAutoObservable(this)
	}

	pushArticle(article) {
		this._articles = [ ...this._articles, article]
	}

	setArticles(articles) {
		this._articles = articles
	}

	get articles() {
		return this._articles
	}
}